<template>
    <div class="ma-3">
        <v-row>
            <v-col cols="12" class="text-center">
                <img :src="organize.chamber" alt="縣市商會聯網" usemap="#workmap">
                <map name="workmap">
                    <area v-for="(item, key) in chamber" :key="key"
                        shape="rect" target="_blank"
                        :coords="item.coords" :alt="item.name" :title="item.name" :href="item.url"
                    >
                </map>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {}
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            chamber: 'Chamber/getChambers'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              getChambers: 'Chamber/getChambers'
          }),
          async init() {
              if(this.organize === '') {
                  await this.getOrganize()
              }
              if(this.chamber === '') {
                  await this.getChambers()
              }
          }
        }
    }
</script>

import axios from 'axios'

axios.defaults.timeout = 10000
axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('authorization')) {
            config.headers.authorization = localStorage.getItem('authorization')
        }
        return config
    },
    error => {
        return Promise.reject(error)
})

axios.interceptors.response.use(
    (response) => {
        const data = response.data.data
        const status = response.data.status
        switch (status) {
            case 1:
                return data
            default:
                return {
                    data: response.data.data,
                    status: response.data.status
                }
        }
    },
    (error) => {
        return Promise.reject(error)
  }
)

export default axios

<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center">
            <v-col cols="12" class="text-right">
                <v-btn  v-for="(item, index) in categorys" :key="index"
                    dark large :color="item.color" class="mx-2" @click="selectType = item.type"
                >
                    {{ item.name }} {{ item.content }}
                    <v-badge color="white" light inline tile :content="getAlltypeCount" v-if="!item.type"></v-badge>
                    <v-badge color="white" light inline tile :content="getTypeCount[item.type] ? getTypeCount[item.type].length : '0'" v-else></v-badge>
                </v-btn>
            </v-col>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3"
                        v-if="selectType === null || selectType === item.type"
                    >
                        <v-row align="center" class="mx-2">
                            <v-col cols="12" sm="2">
                                <v-img :src="item.banner" width="100%" max-height="100px"></v-img>
                            </v-col>
                            <v-col cols="12" sm="10">
                                <v-row dense align="center">
                                    <v-col cols="7">
                                        <span v-for="(category, index) in categorys" :key="index">
                                            <v-chip dark :color="category.color" v-if="category.type === item.type" class="ma-2">
                                                {{ category.name }}
                                            </v-chip>
                                        </span>
                                    </v-col>
                                    <v-col cols="5" class="text-right">
                                        活動日期：{{ item.start_date }} ~ {{ item.end_date }}
                                    </v-col>
                                    <v-col cols="8" class="title"> {{ item.name }} </v-col>
                                    <v-col cols="12" class="body-1">  {{ _limit(item.describe, 145, '') }}{{ item.describe.length > 145 ? '...': '' }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn text color="primary" :to="`/activities/${item.id}`">
                                            查看更多
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    import _groupBy  from 'lodash/groupBy'
    import { limit } from 'stringz';
    export default {
        data () {
            return {
                selectType: null,
                currentPage: 1,
                pageChunk: 20,
                categorys: [{
                    name: '全部',
                    type: null,
                    color: 'black'
                }, {
                    name: '中小企業講習',
                    type: 1,
                    color: 'blue lighten-2'
                }, {
                    name: '稅務講習',
                    type: 2,
                    color: 'green lighten-1'
                }, {
                    name: '其他活動',
                    type: 3,
                    color: 'orange lighten-1'
                }]
            }
        },
        watch: {
            '$route.query.type'() {
                this.init()
            }
        },
        computed: {
          ...mapGetters({
              articles: 'Articles/getArticles'
          }),
          getPages() {
              if (this.articles && this.articles.length > this.pageChunk){
                  return Math.ceil(this.articles.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.articles, this.pageChunk)[this.currentPage - 1]

          },
          getAlltypeCount() {
              return this.articles.length
          },
          getTypeCount() {
              return _groupBy(this.articles, 'type')
          },
          _limit() {
              return limit
          }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getArticles: 'Articles/getArticles'
            }),
            async init() {
                if(this.$route.query.type) {
                    this.selectType = parseInt(this.$route.query.type)
                }
                if (!this.articles) {
                    await this.getArticles()
                }
            }
        }
    }
</script>
<style>
    .v-badge__badge{
        color: black !important;
    }
</style>

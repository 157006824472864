<template>
    <div>
        <v-row justify="space-around">
            <v-col cols="auto">
                <v-dialog max-width="600" v-model="diologStatus">
                    <validation-observer v-slot="{ invalid }">
                        <v-card>
                            <v-toolbar color="deep-orange" dark>
                                <h3>{{ Title }}</h3>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="diologStatus = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                        <v-card-text class="mt-3 pa-3">
                            <v-row justify="center">
                                <v-col cols="12" md="8">
                                    <div v-show="apiError" class="red--text pb-3 text-center"> {{ errorMsg }} </div>
                                    <validation-provider name="電子信箱" rules="required|email" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.email" label="電子信箱"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-end mt-2">
                          <v-btn @click="diologStatus = false">關閉</v-btn>
                          <v-btn color="deep-orange" :dark="!invalid" :loading="loading" @click="save()" :disabled="invalid"> 取得新密碼 </v-btn>
                        </v-card-actions>
                      </v-card>
                  </validation-observer>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      diologStatus: false,
      eyeIconPassword: false,
      eyeIconConfirmPassword: false,
      Title: '忘記密碼',
      loading: false,
      apiError: false,
      errorMsg: '操作成功！',
      data: {}
    }
  },
  methods: {
      ...mapActions({
          forget: 'Auth/forget',
      }),
      setOpen () {
          this.diologStatus = true
          this.data = {
              email: ''
          }
      },
      async save () {
          this.loading = true
          this.errorMsg = ''
          this.apiError = false
          const result = await this.forget(this.data)
          if (!result.status) {
              this.diologStatus = false
          } else {
              switch (result.status) {
                  case 101:
                      this.errorMsg = 'Email尚未註冊'
                      this.apiError = true
                      break;
                  default:
                      this.errorMsg = '系統維護中，請稍後再試！'
                      this.apiError = true
              }
          }
          this.data = {
              email: ''
          }
          this.loading = false
      }
  }
}
</script>

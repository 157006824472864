import axios from './axios.js'
import { decode } from 'html-entities'
import dayjs from 'dayjs'
export default {
    namespaced: true,
    state: {
        activities: '',
        activitiesOnlyTop: '',
        activity: '',
    },
    actions: {
        getActivities ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/activities?published[0]=1`).then(response => {
                    commit('setActivities', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getActivitiesOnlyTop ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/activities?top[0]=1&published[0]=1`).then(response => {
                    commit('setActivitiesOnlyTop', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getActivity ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/activities/${id}`).then(response => {
                    commit('setActivity', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setActivities(state, data) {
            data.forEach((item) => {
                item.content = decode(item.content)
                item.date = dayjs(item.date).format('YYYY/MM/DD')
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
            })

            state.activities = data
        },
        setActivitiesOnlyTop(state, data) {
            let activitiesOnlyTop = []
            data.forEach((item) => {
                item.content = decode(item.content)
                item.date = dayjs(item.date).format('YYYY/MM/DD')
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
                if (item.top) {
                    activitiesOnlyTop.push(item)
                }
            })

            state.activitiesOnlyTop = activitiesOnlyTop
        },
        setActivity(state, data) {
            data.content = decode(data.content)
            data.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.banner}`
            state.activity = data
        }
    },
    getters: {
        getActivities: state => {
          return state.activities
        },
        getActivitiesOnlyTop: state => {
          return state.activitiesOnlyTop
        },
        getActivity: state => {
          return state.activity
        },
    }
}

<template>
    <div class="ma-3">
        <v-row dense justify="center">
            <v-col cols="12" md="11">
                <h1>商人節由來</h1>
            </v-col>
            <v-col cols="12" md="11">
                <v-card flat color="lime lighten-4">
                    <v-card-text class="title">
                        <v-row justify="center" align="center">
                            <v-col cols="12">
                                1946年定每年11月1日為「商人節」，係紀念「中華民國商會全國聯合會」的誕生，並象徵全國商業界團結合作的精神。從此每年11月1日的「商人節」，全國商業界也都熱烈舉行慶祝。<br><br>
                                1941年6月抗戰中期，全國商業界人士集會重慶陪都，開始計議籌備全國商會聯合會；抗戰勝利後，全國商業界人士於1946年5月間召開籌備會議，會後全體與會人員赴中山陵謁陵，並決定同年11月1日在南京正式召開第一屆全國會員代表大會。
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-img :src="images[0]"></v-img>
                            </v-col>
                            <v-col cols="12" md="3" class="body-1 blue--text">
                                1946年陳啟清先生繼任「台灣省商會聯合會」理事長，代表前往南京參加全國商業總會成立大會及制憲國民大會，圖為台灣省十七名制憲代表（陳啟清理事長於前排右四）與先總統 蔣公合影留念。
                            </v-col>
                            <v-col cols="12">
                                1946年11月1日，全國各省市商會和海外各地華僑商會約50個單位，由全國各省市商會及海外華商商會等44個團體約400位會員代表，經奉社會部的召集，共同集會於首都南京，在南京市香舖營公餘聯歡社隆重揭幕，成立我國商會全國聯合會的組織，會期四天，通過重要議案多件，加強全國商人大團結，誠屬空前盛事。大會並通過定是日為「商人節」，藉以慶祝此一推行全國商運的法定最高機構的誕辰，它象徵著商人的大團結，也建立了商人的新形象，協助政府對內推動經濟建設，對外開拓國際貿易市場，展望國家前途，誠然擔負了非常重要的使命。<br><br>
                                是日大會主席由王曉籟先生擔任，致開幕詞並報告大會籌備經過。會中蒙國民政府主席 蔣公躬親蒞會致訓，並題頒「同心拓業」四字，期勉與會代表體認 國父在實業計劃中之訓示，尤以八年抗戰，終獲勝利，必須認清國家現勢，力求發展，俾工商業及時振興，協助政府，完成建國工作。凡我商界同仁，當永銘座右，奉為圭臬。會中並請社會部長谷正綱、經濟部長王雲五、中央組織部長陳立夫與僑務委員會委員長陳樹人等相繼演講，勗勉有加，與會者莫不振奮。<br><br>
                                大會期中，聽取各地代表報告當地商會組織、當時工商業概況及產銷情形，分組審查大會提案200餘件，通過大會宣言及章程，上電國民政府主席 蔣公致敬，並選舉第一屆理監事，結果王曉籟先生當選首屆理事長，復經全體代表一致通過定每年11月1日為「商人節」，經報請社會部核定頒行迄今。會後全體與會代表同赴中山陵致敬。最後大會呼籲全國同胞，無分黨派，真誠合作，化干戈為玉帛，使淪陷地區經濟早日復甦，使工商業基礎賴以奠定，對建國大業有所貢獻。與會代表咸認全國商會同仁於抗戰勝利後集會首都，充分表現大團結，一致為繁榮國家社會而努力，殊具有時代意義。
                                政府遷台後，每屆「商人節」，各商業團體及所屬會員們均有熱烈慶祝，以資紀念。
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-img :src="images[1]"></v-img>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-img :src="images[2]"></v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                images: [require('./1.jpeg'), require('./2.jpeg'), require('./3.jpeg')]
            }
        }
    }
</script>

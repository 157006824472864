import axios from './axios.js'
import groupBy from 'group-array'

export default {
    namespaced: true,
    state: {
        supervisors: ''
    },
    actions: {
        getSupervisors ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/supervisors`).then(response => {
                    commit('setSupervisors', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setSupervisors(state, data){
            data.forEach((item) => {
                item.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.image}`
            })
            state.supervisors = groupBy(data, 'level')
        }
    },
    getters: {
        getSupervisors: state => {
          return state.supervisors
        }
    }
}

<template>
    <div v-if="show">
        <pdf
            :src="taxWorkshop.file"
            v-for="i in taxWorkshop.numPages"
            :key="i"
            :page="i"
        ></pdf>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import pdf from 'vue-pdf'
    const createLoadingTask = pdf.createLoadingTask
    export default {
        data () {
            return {
                show: false,
                taxWorkshop: {}
            }
        },
        components: {
            pdf
        },
        computed: {
            ...mapGetters({
                organize: 'Organize/getOrganize'
            }),
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getOrganize: 'Organize/getOrganize'
            }),
            async init() {
                if(this.organize === ''){
                    await this.getOrganize()
                }
                if (this.organize.tax_workshop) {
                    const url = this.organize.tax_workshop
                    const loadTask = createLoadingTask({
                        url: url
                    })
                    loadTask.promise.then((pdf) => {
                        this.taxWorkshop.file = loadTask,
                        this.taxWorkshop.numPages = pdf.numPages
                        this.show = true
                    })
                }
            }
        }
    }
</script>

import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        magazines: '',
        magazinesYears: []
    },
    actions: {
        getMagazines ({commit}, params) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/magazines`, { params }).then(response => {
                    commit('setMagazines', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setMagazines(state, data) {
            data.forEach((item) => {
                item.file = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.file}`
                state.magazinesYears.push(item.year)
            })
            state.magazines = data
        }
    },
    getters: {
        getMagazines: state => {
            return state.magazines
        },
        getMagazinesYears: state => {
            return state.magazinesYears
        },
    }
}

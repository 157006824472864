import axios from './axios.js'
import { decode } from 'html-entities'

export default {
    namespaced: true,
    state: {
        chairman: ''
    },
    actions: {
        getChairman ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/chairman`).then(response => {
                    commit('setChairman', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setChairman(state, data){
            data.introduce = decode(data.introduce)
            data.content = decode(data.content)
            data.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.image}`
            state.chairman = data
        }
    },
    getters: {
        getChairman: state => {
          return state.chairman
        },
    }
}

import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)

import goTo from 'vuetify/lib/services/goto'
import stores  from './stores'

import Home from './components/Home'
import Tcoc from './components/Tcoc'
import Chairman from './components/Chairman'
import Chambers from './components/Chambers'
import Supervisors from './components/Supervisors'
import MemberGroup from './components/MemberGroup'
import MemberGroupDetail from './components/MemberGroupDetail'
import TcocDynamic from './components/TcocDynamic'
import TcocDynamicDetial from './components/TcocDynamicDetial'
import Magazines from './components/Magazines'
import TcocEdm from './components/TcocEdm'
import Activities from './components/Activities'
import ActivityDetial from './components/ActivityDetial'
import MerchantDay from './components/MerchantDay'
import MerchantDayOfCountry from './components/MerchantDay'
import MerchantDayIntroduce from './components/MerchantDayIntroduce'
import UserActivity from './components/UserActivity'
import TcocGroupActivities from './components/TcocGroupActivities'
import TcocTaxWorkshop from './components/TcocTaxWorkshop'
import TcocRent from './components/TcocRent'

let findPath = ((target, getName) => {
    const targets = target.split('/')
    let response = [{
        to: '/',
        text: '首頁'
    }]
    routes.forEach((item) => {
        if (targets.indexOf(item.name) !== -1) {
            response.push({
                to: item.path,
                text: item.meta.text
            })
        }
    })
    if (getName) {
        response.push({
            to: null,
            text: stores.getters[getName].name || '-'
        })
    }
    return response
})

const routes = [
    {
        path: '*',
        component: Home
    },
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/userActivity',
        name: 'userActivity',
        component: UserActivity,
        meta: {
            text: '帳號管理',
            breadCrumbs() {
                return findPath('/userActivity')
            }
        }
    },
    {
        path: '/tcoc',
        name: 'tcoc',
        component: Tcoc,
        meta: {
            text: '商會簡介',
            breadCrumbs() {
                return findPath('/tcoc')
            }
        }
    },
    {
        path: '/tcoc/chairman',
        name: 'chairman',
        component: Chairman,
        meta: {
            text: '理事長',
            breadCrumbs() {
                return findPath('/tcoc/chairman')
            }
        }
    },
    {
        path: '/tcoc/supervisors',
        name: 'supervisors',
        component: Supervisors,
        meta: {
            text: '理監事',
            breadCrumbs() {
                return findPath('/tcoc/supervisors')
            }
        }
    },
    {
        path: '/tcoc/memberGroup',
        name: 'memberGroup',
        component: MemberGroup,
        meta: {
            text: '會員團體',
            breadCrumbs() {
                return findPath('/tcoc/memberGroup')
            }
        }
    },
    {
        path: '/tcoc/memberGroup/:id',
        component: MemberGroupDetail,
        meta: {
            text: '會員團體詳細內容',
            breadCrumbs() {
                return findPath('/tcoc/memberGroup/:id', 'MemberGroups/getMemberGroup')
            }
        }
    },
    {
        path: '/tcocDynamic',
        name: 'tcocDynamic',
        component: TcocDynamic,
        meta: {
            text: '商會動態',
            breadCrumbs() {
                return findPath('/tcocDynamic')
            }
        }
    },
    {
        path: '/tcocDynamic/magazines',
        name: 'magazines',
        component: Magazines,
        meta: {
            text: '商會月刊',
            breadCrumbs() {
                return findPath('/tcocDynamic/magazines')
            }
        }
    },
    {
        path: '/tcocDynamic/tcocEdm',
        name: 'tcocEdm',
        component: TcocEdm,
        meta: {
            text: '電子報',
            breadCrumbs() {
                return findPath('/tcocDynamic/tcocEdm')
            }
        }
    },
    {
        path: '/tcocDynamic/:id',
        component: TcocDynamicDetial,
        meta: {
            text: '文章內容',
            breadCrumbs() {
                return findPath('/tcocDynamic/:id', 'Activities/getActivity')
            }
        }
    },
    {
        path: '/activities',
        name: 'activities',
        component: Activities,
        meta: {
            text: '活動課程',
            breadCrumbs() {
                return findPath('/activities')
            }
        }
    },
    {
        path: '/activities/:id',
        component: ActivityDetial,
        meta: {
            text: '活動課程',
            breadCrumbs() {
                return findPath(`/activities/:id`, 'Articles/getArticle')
            }
        }
    },
    {
        path: '/merchantDay',
        name: 'merchantDay',
        component: MerchantDay,
        meta: {
            text: '商人節',
            breadCrumbs() {
                return findPath('/merchantDay')
            }
        }
    },
    {
        path: '/merchantDayOfCountry',
        name: 'merchantDayOfCountry',
        component: MerchantDayOfCountry,
        meta: {
            text: '商人節',
            breadCrumbs() {
                return findPath('/merchantDayOfCountry')
            }
        }
    },
    {
        path: '/merchantDay/merchantDayIntroduce',
        name: 'merchantDayIntroduce',
        component: MerchantDayIntroduce,
        meta: {
            text: '商人節由來',
            breadCrumbs() {
                return findPath('/merchantDay/merchantDayIntroduce')
            }
        }
    },
    {
        path: '/tcocGroupActivities',
        name: 'tcocGroupActivities',
        component: TcocGroupActivities,
        meta: {
            text: '中小商業服務活動',
            breadCrumbs() {
                return findPath('/tcocGroupActivities')
            }
        }
    },
    {
        path: '/tcocTaxWorkshop',
        name: 'tcocTaxWorkshop',
        component: TcocTaxWorkshop,
        meta: {
            text: '稅務講習',
            breadCrumbs() {
                return findPath('/tcocTaxWorkshop')
            }
        }
    },
    {
        path: '/tcocRent',
        name: 'tcocRent',
        component: TcocRent,
        meta: {
            text: '場地租借',
            breadCrumbs() {
                return findPath('/tcocRent')
            }
        }
    },
    {
        path: '/chambers',
        name: 'chambers',
        component: Chambers,
        meta: {
            text: '台灣縣市商會聯網',
            breadCrumbs() {
                return findPath('/chambers')
            }
        }
    },
]

export default new VueRouter({
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes: routes,
})

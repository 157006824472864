<template>
    <div class="ma-3">
        <v-row dense>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="12" md="2">
                                <v-img :aspect-ratio="16/9" :src="item.banner"></v-img>
                            </v-col>
                            <v-col cols="12" md="10">
                                <v-row dense align="center">
                                    <v-col cols="12" md="10" class="title"> {{ item.name }} </v-col>
                                    <v-col cols="12" md="2" class="text-right">
                                        <span> {{ item.date }} </span>
                                    </v-col>
                                    <v-col cols="12" class="body-1" v-if="$vuetify.breakpoint.mdAndUp"> {{ _limit(item.describe, 150, '') }}{{ item.describe.length > 150 ? '...': '' }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn text color="deep-orange" :to="`/tcocDynamic/${item.id}`">
                                            查看更多
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    import { limit } from 'stringz';
    export default {
        data () {
            return {
                currentPage: 1,
                pageChunk: 20
            }
        },
        computed: {
          ...mapGetters({
              activities: 'Activities/getActivities'
          }),
          getPages() {
              if (this.activities && this.activities.length > this.pageChunk){
                  return Math.ceil(this.activities.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.activities,this.pageChunk)[this.currentPage - 1]

          },
          _limit() {
              return limit
          }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getActivities: 'Activities/getActivities'
            }),
            async init() {
                if (!this.activities) {
                    await this.getActivities()
                }
            }
        }
    }
</script>

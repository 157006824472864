<template>
    <div class="ma-3">
        <v-row v-if="memberGroup">
            <v-col cols="12" md="6">
                <v-card class="py-3 px-5" color="amber lighten-3">
                    <v-row dense align="center">
                        <v-col cols="9" class="title">
                            <h3> {{ memberGroup.name }} </h3>
                        </v-col>
                        <v-col cols="3" class="text-right">
                            <v-btn icon :href="'https://www.google.com.tw/maps/place/'+memberGroup.address">
                                <v-icon color="blue darken-1"> mdi-map-marker</v-icon>
                            </v-btn>
                            <v-btn icon :href="memberGroup.website">
                                <v-icon color="blue darken-1"> mdi-web</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="5" md="3">
                            <h4>
                                <v-icon color="green darken-2"> mdi-phone</v-icon>
                                電話
                            </h4>
                        </v-col>
                        <v-col cols="7" md="3" class="subtitle-1">{{ memberGroup.phone }}</v-col>
                        <v-col cols="5" md="3">
                            <h4>
                                <v-icon color="green darken-2"> mdi-fax</v-icon>
                                傳真
                            </h4>
                        </v-col>
                        <v-col cols="7" md="3" class="subtitle-1">{{ memberGroup.fax }}</v-col>
                        <v-col cols="5" md="3">
                            <h4>
                                <v-icon color="green darken-2"> mdi-domain</v-icon>
                                地址
                            </h4>
                        </v-col>
                        <v-col cols="7" md="9" class="subtitle-1">{{ memberGroup.address }}</v-col>
                        <v-col cols="5" md="3">
                            <h4>
                                <v-icon color="green darken-2"> mdi-email</v-icon>
                                電子郵件
                            </h4>
                        </v-col>
                        <v-col cols="7" md="9" class="subtitle-1">{{ memberGroup.email }}</v-col>
                    </v-row>
                </v-card>
                <v-card class="py-3 px-5 mt-5" color="amber lighten-3">
                    <v-row dense align="center">
                        <v-col cols="12">
                            <h3> 理監事名單 </h3>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table class="amber lighten-3">
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-for="(item, index) of supervisorsShow" :key="index">
                                            <td width="120px">
                                                <h4>{{memberGroup.level[index]}}</h4>
                                            </td>
                                            <td class="subtitle-1">{{ item }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="py-3 px-5" color="amber lighten-3">
                    <v-row dense align="center">
                        <v-col cols="9" class="title">
                            <h3> {{ getGroupType }}</h3>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table class="amber lighten-3">
                                <template v-slot:default>
                                    <tbody color="">
                                        <tr v-for="item in memberGroup.basic_groups" :key="item.id">
                                            <td class="subtitle-1">{{ item.name }}</td>
                                            <td class="subtitle-1">
                                                {{ item.address }}
                                                <v-btn icon :href="'https://www.google.com.tw/maps/place/'+item.address">
                                                    <v-icon color="blue darken-1"> mdi-map-marker</v-icon>
                                                </v-btn>
                                            </td>
                                            <td class="subtitle-1">{{ item.phone }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import _map from 'lodash/map'
    export default {
        data () {
            return {
                memberGroup: null,
                supervisorsShow: []
            }
        },
        computed: {
          ...mapGetters({
                basicGroups: 'MemberGroups/getBasicGroups',
            }),
            getGroupType() {
                let result = this.memberGroup.name || ''
                if (result.indexOf('商業會') !== -1) {
                    result = result.replace('商業會', '同業公會')
                }
                if (result.indexOf('台灣省') !== -1) {
                    result = result.replace('台灣省', '台灣省各縣市')
                }
                if (result.indexOf('聯合會') !== -1) {
                    result = result.replace('聯合會', '')
                }
                return result
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getBasicGroups: 'MemberGroups/getBasicGroups',
                getMemberGroup: 'MemberGroups/getMemberGroup'
            }),
            async init() {
                if(this.basicGroups === ''){
                    await this.getBasicGroups()
                }
                this.memberGroup = await this.getMemberGroup(this.$route.params.id)
                for (let i = 0 ; i < this.memberGroup.level.length ; i++) {
                    this.supervisorsShow[i] = _map(this.memberGroup.supervisorsGroup[i],'name').toString()
                }
            }
        }
    }
</script>

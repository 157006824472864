<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center" v-if="edms && edmsYears">
            <v-col cols="6" md="2" >
                <v-select :items="edmsYears" label="請選擇年份" outlined color="deep-orange" hide-details clearable v-model="findValue.year" @change="findEdms()"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select :items="months" label="請選擇月份" outlined color="deep-orange" hide-details clearable v-model="findValue.month" @change="findEdms()"></v-select>
            </v-col>
            <v-col cols="12" v-for="(item, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="12" md="8">
                                <div style="position: relative;padding-bottom: 35%;overflow: hidden;">
                                    <iframe height="300vh" width="700vw"
                                        :src="item.url"
                                        frameborder="0"
                                        style="position: absolute;top: 0;left: 0;"
                                    >
                                        <p>很抱歉，您的瀏覽器不支援網頁預覽，請點擊右側預覽。</p>
                                    </iframe>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-row dense align="center">
                                    <v-col cols="8" class="title">
                                        <h6>台灣商業訊息報 - 電子報</h6>
                                        {{ item.year }}年{{ item.month }}月
                                    </v-col>
                                    <v-col cols="12" class="body-1"> {{ item.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn class="mx-3" text dark color="primary" :href="item.url" target="_blank">
                                            查看更多
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    export default {
        data () {
            return {
                currentPage: 1,
                pageChunk: 20,
                months: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                findValue: {
                    year: null,
                    month: null,
                }
            }
        },
        computed: {
          ...mapGetters({
              edms: 'Edms/getEdms',
              edmsYears: 'Edms/getEdmsYears'
          }),
          getPages() {
              if (this.edms && this.edms.length > this.pageChunk){
                  return Math.ceil(this.edms.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.edms, this.pageChunk)[this.currentPage - 1]

          }
      },
      created() {
          this.init()
      },
      methods:{
          ...mapActions({
              getEdms: 'Edms/getEdms'
          }),
          async findEdms() {
              let request = {}
              if (this.findValue.year) {
                  request.year = this.findValue.year
              }
              if (this.findValue.month) {
                  request.month = this.findValue.month
              }

              if (request) {
                  await this.getEdms(request)
              } else {
                  await this.getEdms()
              }
          },
          async init() {
              if (!this.edms) {
                  await this.getEdms()
              }
          }
      }
    }
</script>

<template>
    <div class="ma-3">
        <v-row dense align="center">
            <v-col cols="12">
                <h1> {{ data.name }}</h1>
            </v-col>
        </v-row>
        <v-card outlined flat v-if="data.id">
            <v-card-text>
                <v-row justify="space-between" class="px-5">
                    <span class="title">活動資訊</span>
                    <div class="text-right">
                        <span v-for="(category, index) in categorys" :key="index">
                            <v-chip dark :color="category.color" v-if="category.type === data.type" class="ma-2">
                                <h3> {{ category.name }} </h3>
                            </v-chip>
                        </span>
                    </div>
                </v-row>
                <v-row align="center">
                    <v-col cols="12" sm="6">
                        <v-img :src="data.banner" v-if="data.banner"></v-img>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody v-if="$vuetify.breakpoint.smAndUp">
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-domain</v-icon>
                                            主辦單位
                                        </td>
                                        <td class="body-1"> {{ data.host }} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-account</v-icon>
                                            講師
                                        </td>
                                        <td class="body-1 py-2">
                                            <tr>
                                                <td>
                                                    {{ data.teacher }}
                                                </td>
                                                <td v-if="data.teacher_image" class="pl-5">
                                                    <v-avatar size="90" class="mr-3">
                                                        <v-img :src="data.teacher_image"></v-img>
                                                    </v-avatar>
                                                </td>
                                            </tr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-clock-time-nine-outline</v-icon>
                                            活動時間
                                        </td>
                                        <td class="body-1"> {{ data.start_date }}  {{ data.start_time }} ~ {{ data.end_date }}  {{ data.end_time }}</td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-currency-usd</v-icon>
                                            費用資訊
                                        </td>
                                        <td class="body-1">
                                            <v-chip color="cyan lighten-1" class="mr-2" v-if="data.price.length === 0">
                                                <span class="body-1"> 免費課程 </span>
                                            </v-chip>
                                            <v-chip v-for="(item, index) in data.price" :key="index" color="cyan lighten-1" class="mr-2" v-else>
                                                <span class="body-1"> {{ item.name }}  {{ item.value }} </span>
                                            </v-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-map-marker</v-icon>
                                            活動地點
                                        </td>
                                        <td class="py-2">
                                            <v-chip color="teal accent-3" class="mr-2" v-if="data.address_online">
                                                <span class="body-1">線上活動</span>
                                            </v-chip>
                                            <v-chip color="teal accent-3" class="mr-2" v-if="data.address_entity">
                                                <span class="body-1">實體活動</span>
                                            </v-chip><br>
                                            <span class="body-1" v-if="data.address">{{ data.address }} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-domain</v-icon>
                                            聯絡人
                                        </td>
                                        <td class="body-1"> {{ data.contact}} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-phone</v-icon>
                                            聯絡電話
                                        </td>
                                        <td class="body-1"> {{ data.phone}} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-email</v-icon>
                                            Email
                                        </td>
                                        <td class="body-1"> {{ data.email}} </td>
                                    </tr>
                                    <tr v-if="data.website !== ''">
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-web</v-icon>
                                            活動網址
                                        </td>
                                        <td class="body-1"> <a :href="data.website"> {{ data.website }} </a></td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-domain</v-icon>
                                            主辦單位
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> {{ data.host }} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-account</v-icon>
                                            講師
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 py-2">
                                            <tr>
                                                <td v-if="data.teacher_image" class="pl-5">
                                                    <v-avatar size="90" class="mr-3">
                                                        <v-img :src="data.teacher_image"></v-img>
                                                    </v-avatar>
                                                </td>
                                                <td>
                                                    {{ data.teacher }}
                                                </td>
                                            </tr>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-clock-time-nine-outline</v-icon>
                                            活動時間
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> {{ data.start_date }}  {{ data.start_time }} ~ <br> {{ data.end_date }}  {{ data.end_time }}</td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-currency-usd</v-icon>
                                            費用資訊
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right">
                                            <v-chip v-for="(item, index) in data.price" :key="index" color="cyan lighten-1" class="mr-2">
                                                <span class="body-1"> {{ item.name }}  {{ item.value }} </span>
                                            </v-chip>
                                            <v-chip color="cyan lighten-1" class="mr-2" v-if="data.price.length === 0">
                                                <span class="body-1"> 免費課程 </span>
                                            </v-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-map-marker</v-icon>
                                            活動地點
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="py-2 text-right">
                                            <v-chip color="teal accent-3" class="mr-2" v-if="data.address_online">
                                                <span class="body-1">線上活動</span>
                                            </v-chip>
                                            <v-chip color="teal accent-3" class="mr-2" v-if="data.address_entity">
                                                <span class="body-1">實體活動</span>
                                            </v-chip><br>
                                            <span class="body-1" v-if="data.address">{{ data.address }} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-domain</v-icon>
                                            聯絡人
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> {{ data.contact}} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-phone</v-icon>
                                            聯絡電話
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> {{ data.phone}} </td>
                                    </tr>
                                    <tr>
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-email</v-icon>
                                            Email
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> {{ data.email}} </td>
                                    </tr>
                                    <tr v-if="data.website !== ''">
                                        <td class="title">
                                            <v-icon color="green darken-2">mdi-web</v-icon>
                                            活動網址
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="body-1 text-right"> <a :href="data.website"> {{ data.website }} </a></td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card flat>
            <div v-html="data.content" class="pa-3 tinymce"></div>
        </v-card>
        <v-card flat outlined color="grey lighten-2">
            <v-card-text class="Caption">
                台灣商會聯合資訊網聲明：<br>
                本活動訊息為網路會員自由刊登，本網並非主辦單位僅作文字允當之審核，報名參加活動者若發生任何爭議，請自行與主辦單位聯繫解決，本網歉難負責。
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    import dayjs from 'dayjs'
    export default {
        data () {
            return {
                currentId: null,
                categorys: [{
                    name: '中小企業講習',
                    type: 1,
                    color: 'blue lighten-2'
                }, {
                    name: '稅務講習',
                    type: 2,
                    color: 'green lighten-1'
                }, {
                    name: '其他活動',
                    type: 3,
                    color: 'orange lighten-1'
                }],
                data: {}
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getArticle: 'Articles/getArticle'
            }),
            async init() {
                this.currentId = this.$route.params.id
                this.data = await this.getArticle(this.currentId)
                this.data.start_date = dayjs(this.data.start_date).format('YYYY/MM/DD')
                this.data.end_date = dayjs(this.data.end_date).format('YYYY/MM/DD')
            }
        }
    }
</script>

<template>
    <div class="ma-3">
        <v-row :justify="index < 3 ? 'space-around': ''" class="ma-5" v-for="(item, index) in supervisors" :key="index">
            <v-col cols="12" v-if="index == 3">
                <v-divider class="mb-5"></v-divider>
                <h1>理事會</h1>
            </v-col>
            <v-col cols="12" v-if="index == 5">
                <v-divider class="mb-5"></v-divider>
                <h1>監事會</h1>
            </v-col>
            <v-col cols="6" sm="4" md="2" v-for="(member, index) in item" :key="index">
                <v-card flat color="#F4F4F4">
                    <v-row dense justify="center" class="black--text ma-3">
                        <v-col cols="12" class="pb-0 pt-2">
                            <span class="text-sm-h6 text-subtitle-2"> {{ member.genre_name }}</span>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-img :aspect-ratio="3/4" width="100%" :src="member.image"></v-img>
                        </v-col>
                        <v-col cols="12" class="text-right">
                            <h2 v-if="$vuetify.breakpoint.smAndUp">{{ member.name }}</h2>
                            <h3 v-else>{{ member.name }}</h3>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {}
        },
        computed: {
          ...mapGetters({
                supervisors: 'Supervisors/getSupervisors',
            })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getSupervisors: 'Supervisors/getSupervisors'
            }),
            init() {
                if(this.supervisors === ''){
                    this.getSupervisors()
                }
                if(this.chairman === ''){
                    this.getChairman()
                }
            }
        }
    }
</script>

<template>
    <div class="ma-3">
        <v-row dense align="center" v-if="merchantDay && merchantDayYears">
            <v-col cols="12" sm="10">
                <h1 v-if="merchantDay">第{{merchantDay.number}}屆商人節大會</h1>
            </v-col>
            <v-col cols="12" sm="2" class="text-right">
                <v-select dense label="年度" :items="merchantDayYears" outlined v-if="merchantDayYears"
                    color="primary" hide-details :value="merchantDay ? merchantDay.year : ''" @change="changeYear"
                ></v-select>
            </v-col>
            <v-col cols="12">
                <v-row justify="center" class="text-center">
                    <v-col cols="6" md="4">
                        <v-btn :outlined="selectedTab !== 0" :dark="selectedTab === 0" rounded  color="deep-orange" @click="selectedTab = 0">商人節活動報導</v-btn>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-btn :outlined="selectedTab !== 1" :dark="selectedTab === 1" rounded color="deep-orange" @click="selectedTab = 1">各縣市活動剪影</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-tabs-items v-model="selectedTab" class="pa-3">
                    <v-tab-item>
                        <v-card flat color="lime lighten-4" class="pa-3">
                            <div class="text-right pa-3">
                                <v-btn outlined rounded color="deep-orange" :href="merchantDay.edmPath" target="_blank">表揚名單</v-btn>
                            </div>
                            <div class="tinymce" v-html="merchantDay.content"></div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row justify="center" align="stretch">
                            <v-col cols="12" sm="6" v-for="(item, index) in merchantDay.id" :key="index">
                                <v-card outlined color="blue-grey lighten-5">
                                    <v-card-text>
                                        <v-img contain :aspect-ratio="16/9" :src="merchantDay.image[index]"></v-img>
                                        <span> {{ merchantDay.name[index] }} </span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                selectedTab: 0
            }
        },
        computed: {
            ...mapGetters({
                merchantDays: 'MerchantDay/getMerchantDays',
                merchantDay: 'MerchantDay/getMerchantDay',
                merchantDayYears: 'MerchantDay/getMerchantDayYears'
            })
        },
        watch: {
            '$route.name'() {
                this.init()
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getMerchantDays: 'MerchantDay/getMerchantDays',
                getMerchantDay: 'MerchantDay/getMerchantDay',
            }),
            async init() {
                this.selectedTab = 0
                if (!this.merchantDays) {
                    await this.getMerchantDays()
                }
                if(this.$route.name === 'merchantDayOfCountry') {
                    this.selectedTab = 1
                }
            },
            async changeYear(year) {
                await this.getMerchantDay(year)
            }
        }
    }
</script>

import axios from './axios.js'
import { decode } from 'html-entities'
export default {
    namespaced: true,
    state: {
        merchantDays: null,
        merchantDay: null,
        merchantDayYears: []
    },
    actions: {
        getMerchantDays ({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/merchantDay`).then(response => {
                    commit('setMerchantDays', response)
                    if (response.length > 0) {
                        dispatch('getMerchantDay', response[0].year)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getMerchantDay ({commit}, year) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/merchantDay/${year}`).then(response => {
                    commit('setMerchantDay', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setMerchantDays(state, data) {
            state.merchantDayYears = []
            data.forEach((item) => {
                item.content = decode(item.content)
                state.merchantDayYears.push(item.year)
            })
            state.merchantDays = data
        },
        setMerchantDay(state, data) {
            data.content = decode(data.content)
            data.id = data.id ? data.id.split(',') : []
            data.name = data.name ? data.name.split(',') : []
            data.sort = data.sort ? data.sort.split(',') : []
            data.maxSort = data.maxSort ? data.maxSort : 0
            data.edmPath = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.edmPath}`

            let image = data.image ? data.image.split(',') : []
            image.forEach((value, key) => {
                image[key] = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${value}`
            })
            data.image = image
            state.merchantDay = data
        }
    },
    getters: {
        getMerchantDays: state => {
          return state.merchantDays
        },
        getMerchantDay: state => {
          return state.merchantDay
        },
        getMerchantDayYears: state => {
          return state.merchantDayYears
        },
    }
}

<template>
    <v-row justify="space-around">
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="diologStatus">
                <validation-observer v-slot="{ invalid }">
                    <v-card>
                        <v-toolbar color="deep-orange" dark>
                            <h3>{{ Title }}</h3>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="diologStatus = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="mt-3 pa-3">
                            <v-row dense justify="center">
                                <span v-show="apiError" class="red--text pb-3"> {{ errorMsg }} </span>
                                <v-col cols="12" md="8">
                                    <validation-provider name="電子信箱" rules="required|email" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.email" label="電子信箱"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="所屬團體/中小企業" rules="required" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.group_name" label="所屬團體/中小企業"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.name" label="姓名"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="聯絡電話" rules="required|numeric" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.phone" label="聯絡電話"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-divider class="ma-2"></v-divider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8|confirmedA:confirmPassword" vid="password" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="data.password" :error-messages="errors"
                                            :append-icon="eyeIconPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="eyeIconPassword ? 'text' : 'password'"
                                            label="密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="eyeIconPassword = !eyeIconPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8|confirmedB:password" vid="confirmPassword" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="data.confirmPassword" :error-messages="errors"
                                            :append-icon="eyeIconConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="eyeIconConfirmPassword ? 'text' : 'password'"
                                            label="確認密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="eyeIconConfirmPassword = !eyeIconConfirmPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-row align="center" class="px-2" v-if="$vuetify.breakpoint.smAndUp">
                                        <v-checkbox
                                            v-model="confirmRuleCheckBox"
                                            label=""
                                        ></v-checkbox>
                                        <span>我同意，台灣商會聯合資訊網網路會員權利及隱私權聲明:</span>
                                    </v-row>
                                    <v-row align="center" class="px-2" v-if="$vuetify.breakpoint.xsOnly">
                                        <v-col cols="1">
                                            <v-checkbox
                                                v-model="confirmRuleCheckBox"
                                                label=""
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col cols="11">
                                            <span>我同意，台灣商會聯合資訊網網路會員權利及隱私權聲明:</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="8" class="pt-0">
                                    <span>
                                        <router-link :to="{ name: 'tcocEdm'}" target="_blank">
                                            1.免費收閱本網提供之電子報、活動快訊。
                                        </router-link>
                                    </span><br>
                                    <span>
                                        <router-link :to="{ name: 'tcocRent'}" target="_blank">
                                            2.租借台灣省商業總會會議室場地八折優惠。
                                        </router-link>
                                    </span><br>
                                    <span>
                                        <a>
                                            3.得經審核後免費於本網刊登商業服務類活動、講座或課程。
                                        </a>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-space-between mt-2">
                            <div>
                                <v-btn text @click="openThirdTarget()">我已有帳號，返回登入</v-btn>
                            </div>
                            <div class="">
                                <v-btn @click="diologStatus = false" class="mx-2">關閉</v-btn>
                                <v-btn color="deep-orange" :dark="!invalid && confirmRuleCheckBox" :loading="loading" @click="save()" :disabled="invalid || !confirmRuleCheckBox"> 註冊 </v-btn>
                            </div>
                        </v-card-actions>
                     </v-card>
                </validation-observer>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      diologStatus: false,
      eyeIconPassword: false,
      eyeIconConfirmPassword: false,
      Title: '註冊',
      loginFunction: null,
      loading: false,
      confirmRuleCheckBox: false,
      data: {
        email: '',
        group_name: '',
        name: '',
        phone: '',
        password: '',
        confirmPassword: ''
      },
      errorMsg: '',
      apiError: false
    }
  },
  computed: {
    ...mapGetters({
      token: 'Auth/getToken'
    })
  },
  methods: {
      ...mapActions({
          signUp: 'Auth/signUp'
      }),
      setOpen (target) {
          this.diologStatus = true
          this.data = {
              email: '',
              group_name: '',
              name: '',
              phone: '',
              password: '',
              confirmPassword: '',
              subscription: true
          }
          this.loginFunction = target
      },
      async save () {
          this.errorMsg = ''
          this.apiError = false
          const response = await this.signUp(this.data)
          switch (response.status) {
              case 101:
                  this.errorMsg = 'Email已存在，請透過密碼登入或使用忘記密碼'
                  this.apiError = true
                  break
              default:
                this.diologStatus = false
          }
      },
      openThirdTarget() {
          this.diologStatus = false
          this.loginFunction(this.setOpen)
      }
  }
}
</script>

import axios from './axios.js'
import { decode } from 'html-entities'
export default {
    namespaced: true,
    state: {
        organize: '',
        result: null,
    },
    actions: {
        getOrganize ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/organize`).then(response => {
                    commit('setOrganize', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setOrganize(state, data) {
            data.describe = decode(data.describe)
            data.content = decode(data.content)
            data.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.image}`
            data.masters = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.masters}`
            data.group_activities = data.group_activities?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.group_activities}`: null
            data.tax_workshop = data.tax_workshop?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.tax_workshop}`: null
            data.rent = data.rent?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.rent}`: null
            data.chamber = data.rent?`${process.env.VUE_APP_FILE_SERVER_ADDRESS}${data.chamber}`: null
            state.organize = data
        },
        setResult(state, data) {
            state.result = data
        }
    },
    getters: {
        getOrganize: state => {
          return state.organize
        },
    }
}

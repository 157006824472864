import axios from './axios.js'
import groupBy from 'group-array'

export default {
    namespaced: true,
    state: {
        memberGroups: '',
        basicGroups: '',
        memberGroup: ''
    },
    actions: {
        getMemberGroups ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups?show[0]=1`).then(response => {
                    commit('setMemberGroups', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getMemberGroup ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups/${id}`).then(response => {
                    const levelGroup = groupBy(response.supervisors, 'title')
                    const levelValue = Object.values(levelGroup)
                    response['level']  = Object.keys(levelGroup)
                    for (let i = 0; i < response['level'].length ; i++) {
                        levelValue[i].forEach((item) => {
                            item.titleNo = i
                        })
                    }
                    response.supervisorsGroup = levelValue
                    resolve(response)
                    commit('setMemberGroup', response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getBasicGroups ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/memberGroups/basicGroups/list`).then(response => {
                    commit('setBasicGroups', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setMemberGroups(state, data){
            state.memberGroups = data
        },
        setMemberGroup(state, data){
            state.memberGroup = data
        },
        setBasicGroups(state, data){
            state.basicGroups = data
        }
    },
    getters: {
        getMemberGroups: state => {
          return state.memberGroups
        },
        getMemberGroup: state => {
          return state.memberGroup
        },
        getBasicGroups: state => {
          return state.basicGroups
        }
    }
}

<template>
    <div class="ma-3">
        <v-row dense v-if="data">
            <v-col cols="12">
                <h1> {{ data.name }}</h1>
            </v-col>
            <v-col cols="12" class="text-right">
                <v-icon color="primary"> mdi-calendar-clock </v-icon>
                <span class="title">
                    {{ data.date }}
                </span>
            </v-col>
        </v-row>
        <div class="tinymce" v-html="data.content"></div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        data () {
            return {
                data: {}
            }
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
                getActivity: 'Activities/getActivity'
            }),
            async init() {
                this.currentId = this.$route.params.id
                this.data = await this.getActivity(this.currentId)
            }
        }
    }
</script>

export const state = {
    facebook: null,
    ssoInfo: null
};
export const actions = {
    initFacebook({commit}) {
        window.fbAsyncInit = function () {
            /* global FB */
            FB.init({
                appId: process.env.VUE_APP_FB_APP_ID,
                cookie: true,
                xfbml: true,
                version: process.env.VUE_APP_FB_APP_VERSION,
            });
            commit('setFacebook', FB)
            FB.AppEvents.logPageView();
        };
    },
    initGoogle({commit}, action) {
        const { OAuth2Client } = require('google-auth-library')
        const client = new OAuth2Client(process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID)
        window.onGoogleLibraryLoad = function() {
            /* global google */
            google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
                callback: async function (res) {
                    const ticket = await client.verifyIdToken({
                        idToken: res.credential,
                        audience: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
                    });
                    const payload = ticket.getPayload()
                    commit('setSsoInfo', {
                        email: payload.email,
                        group_name: 'From_Google',
                        name: payload.name || '',
                        phone: '',
                        subscription: true,
                        sso_type: 2,
                        password: payload.sub
                    })
                    action()
                }
            })
            google.accounts.id.prompt()
        }
    },
    loginFacebook({dispatch}) {
        FB.getLoginStatus(function (response) {
            if (response.status !== "connected") {
                FB.login(function() {
                    dispatch('getProfileFacebook')
                    }, { scope: "public_profile,email" }
                )
            } else {
                dispatch('getProfileFacebook')
            }
        });
    },
    getProfileFacebook({commit}){
        FB.api("/me?fields=name,id,email", function(response) {
            commit('setSsoInfo', {
                email: response.email || null,
                group_name: 'From_Facebook',
                name: response.name || '',
                phone: '',
                subscription: true,
                sso_type: 1,
                password: response.id
            })
        })
    }
}

export const mutations = {
    setFacebook(state, payload) {
        state.facebook = payload;
    },
    setSsoInfo(state, payload) {
        state.ssoInfo = payload
    }
};
export const getters = {
    getSsoInfo(state) {
        return state.ssoInfo
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
    namespaced: true,
};

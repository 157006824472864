<template>
    <div class="ma-3">
        <v-tabs grow show-arrows color="deep-orange" v-model="selectedTab">
            <v-tab v-for="(item, index) in tabs" :key="index" v-text="item" class="title" :disabled="index > 0 && account.status !== 1"></v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="py-3">
            <!-- 會員資訊 -->
            <v-tab-item>
                <validation-observer v-slot="{ invalid }">
                    <v-card outlined>
                        <v-card-text>
                            <div class="d-flex">
                                <v-chip label color="#17a2b8" dark class="mb-3">
                                    <h3>個人資料</h3>
                                </v-chip>
                                <v-spacer></v-spacer>
                                <v-tooltip bottom v-if="token">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="grey" v-bind="attrs" v-on="on"
                                            @click="logout"
                                        >
                                            <v-icon>
                                                mdi-logout
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span> 登出 </span>
                                </v-tooltip>
                            </div>
                            <v-row v-if="account.status === 1">
                                <v-col cols="12" md="6">
                                    <v-text-field  outlined dense disabled
                                      v-model="account.email" label="電子信箱"
                                      hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider name="所屬團體/中小企業" rules="required" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="account.group_name" label="所屬團體/中小企業"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider name="姓名" rules="required|alpha_spaces" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="account.name" label="姓名"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <validation-provider name="聯絡電話" rules="required|numeric" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="account.phone" label="聯絡電話"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn :dark="!invalid" color="deep-orange" :disabled="invalid" @click="updateAccount">送出</v-btn>
                                </v-col>
                            </v-row>
                            <span v-else>您需完成變更密碼後，才能進行個人資料維護及文章發表喔！</span>
                        </v-card-text>
                    </v-card>
                </validation-observer>
                <validation-observer v-slot="{ invalid }" v-if="!account.sso_type">
                    <v-card outlined class="mt-5">
                        <v-card-text>
                            <v-chip label color="#17a2b8" dark class="mb-3">
                                <h3>變更密碼</h3>
                            </v-chip>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <validation-provider name="原密碼" rules="required|alpha_dash|min:8" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="password.oldPassword" :error-messages="errors"
                                            :append-icon="showIcon.oldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            placeholder="請輸入舊密碼" :type="showIcon.oldPassword ? 'text' : 'password'"
                                            label="原密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="showIcon.oldPassword = !showIcon.oldPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="新密碼" rules="required|alpha_dash|min:8|confirmedA:confirmPassword" vid="password" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="password.newPassword" :error-messages="errors"
                                            :append-icon="showIcon.newPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            placeholder="請輸入8~18位數新密碼" :type="showIcon.newPassword ? 'text' : 'password'"
                                            label="新密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="showIcon.newPassword = !showIcon.newPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="確認新密碼" rules="required|alpha_dash|min:8|confirmedB:password" vid="confirmPassword" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="password.confirmNewPassword" :error-messages="errors"
                                            :append-icon="showIcon.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            placeholder="請再輸入一次新密碼" :type="showIcon.confirmPassword ? 'text' : 'password'"
                                            label="確認新密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="showIcon.confirmPassword = !showIcon.confirmPassword"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn :dark="!invalid" color="deep-orange" :disabled="invalid" @click="updatePassword">送出</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </validation-observer>
            </v-tab-item>
            <!-- 發表新活動 -->
            <v-tab-item>
                <validation-observer v-slot="{ invalid }">
                    <v-card outlined>
                        <v-card-text v-if="confirmRule">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <validation-provider name="活動課程標題" rules="required" v-slot="{ errors }">
                                        <v-text-field counter="45" outlined label="活動課程標題" v-model="data.name" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="主辦單位" rules="required|alpha|max:45" v-slot="{ errors }">
                                        <v-text-field counter="45" outlined label="主辦單位" v-model="data.host" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="活動圖片" rules="required" v-slot="{ errors }">
                                        <v-file-input outlined accept="image/*" label="活動圖片(海報)" v-model="data.banner" :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <validation-provider name="活動課程描述" rules="required|max:500" v-slot="{ errors }">
                                        <v-text-field counter="500" outlined label="活動課程描述" v-model="data.describe" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <validation-provider name="活動網址" rules="url|max:45" v-slot="{ errors }">
                                        <v-text-field counter="45" outlined label="活動網址" v-model="data.website" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="聯絡人" rules="required|alpha|max:45" v-slot="{ errors }">
                                        <v-text-field counter="45" outlined label="聯絡人" v-model="data.contact" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="聯絡電話" rules="required|phone|max:45" v-slot="{ errors }">
                                        <v-text-field counter="45" label="聯絡電話" outlined v-model="data.phone" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="Email" rules="email|max:45" v-slot="{ errors }">
                                        <v-text-field counter="45" outlined label="Email" v-model="data.email" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="講師" rules="required|alpha|max:30" v-slot="{ errors }">
                                        <v-text-field counter="30" outlined label="講師" v-model="data.teacher" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                    <validation-provider name="講師圖片" v-slot="{ errors }">
                                        <v-file-input outlined accept="image/*" label="講師圖片" v-model="data.teacher_image" :error-messages="errors"></v-file-input>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="起始日" rules="required" v-slot="{ errors }">
                                        <v-text-field outlined type="date" label="起始日" v-model="data.start_date" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                    <validation-provider name="起始時間" rules="required" v-slot="{ errors }">
                                        <v-text-field outlined type="time" label="起始時間" v-model="data.start_time" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <validation-provider name="結束日" rules="required" v-slot="{ errors }">
                                        <v-text-field outlined type="date" label="結束日" v-model="data.end_date" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                    <validation-provider name="結束時間" rules="required" v-slot="{ errors }">
                                        <v-text-field outlined type="time" label="結束時間" v-model="data.end_time" :error-messages="errors"></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span class="subtitle-2">活動地點 (若有實體活動地點，請提供地址)</span>
                                        <v-checkbox hide-details v-model="data.address_online" label="線上活動"></v-checkbox>
                                        <v-checkbox hide-details v-model="data.address_entity" label="實體活動"></v-checkbox>
                                        <validation-provider name="實體活動地點" rules="alpha_dash|max:45" v-slot="{ errors }">
                                            <v-text-field counter="45" outlined label="實體活動地點" v-model="data.address" color="primary" v-show="data.address_entity" class="mt-2" :error-messages="errors"></v-text-field>
                                        </validation-provider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span class="subtitle-2">費用資訊</span>
                                    <span class="subtitle-2">請填寫價位名稱及金額</span>
                                        <v-card flat>
                                            <v-card-text>
                                                <v-row>
                                                    <v-checkbox hide-details label="免費活動" v-model="freePriceStatus"  @change="freePrice"></v-checkbox>
                                                </v-row>
                                                <div v-if="!freePriceStatus" class="mt-5">
                                                    <v-row v-if="data.price[0]">
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[0].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[0].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-if="data.price[1]">
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[1].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[1].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-if="data.price[2]">
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="票種" rules="alpha" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[2].name" color="primary" placeholder="例如：原價" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                        <v-col cols="12" md="6">
                                                            <validation-provider name="售價" rules="numeric" v-slot="{ errors }">
                                                                <v-text-field outlined v-model="data.price[2].value" color="primary" prepend-inner-icon="mdi-currency-usd" placeholder="1500" :error-messages="errors"></v-text-field>
                                                            </validation-provider>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                </v-col>

                                <v-col cols="12">
                                    <v-row class="pa-3" align="end">
                                        <span class="subtitle-2">活動介紹</span>
                                        <input ref="editorImage" type="file" accept="image/*" v-show="false">
                                    </v-row>
                                    <editor
                                        v-model="data.content" api-key="hzfavngfeqhvixw4riq3hlw5b6oliak1djzodq4m52c25jk6"
                                        :init="{
                                            height: 800,
                                            plugins: plugins,
                                            toolbar: toolbar,
                                            file_picker_types: 'image',
                                            paste_data_images: true,
                                            powerpaste_allow_local_images: true,
                                            image_advtab: true,
                                            image_class_list: [{title: 'Responsive', value: 'img-responsive'}],
                                            file_picker_callback: (callback, value, meta) => { editImageUpload(callback, value ,meta)  },
                                        }"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <div class="text-right">
                                        <v-btn @click="confirmRule = confirmRuleCheckBox = false" >取消</v-btn>
                                        <v-btn class="ml-2" @click="sendDialog = true" :disabled="invalid" :loading="loading">送出審核</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else>
                            <h2>台灣商會聯合資訊網受理網路會員免費刊登活動訊息申請須知：</h2><br>
                            <div class="subtitle-1">
                                1. 活動訊息至遲應於開辦前三天提出申請。<br>
                                2. 活動內容應與商業服務有關之活動、講座或課程。<br>
                                3. 活動訊息刊登與否需經本網審查，如有違背公序良俗、虛偽不實、違反政府法令規定者，本網有權拒絕刊登或撤除。<br>
                                4. 活動訊息刊登後，參與者若發生任何爭議，概由刊登者與主辦單位自行與參與者依循正當法律途徑解決，本會歉難負責。
                            </div>
                            <div class="text-right">
                                <v-checkbox
                                    v-model="confirmRuleCheckBox"
                                    label="我同意上述規定"
                                ></v-checkbox>
                                <v-btn  @click="confirmRule = true" :disabled="!confirmRuleCheckBox">開始刊登</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </validation-observer>
            </v-tab-item>
            <!-- 已申請的活動 -->
            <v-tab-item>
                <v-row dense justify="end" align="center">
                    <v-col cols="12" v-for="(item, index) in articles" :key="index">
                        <v-hover v-slot="{ hover }">
                            <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                                <v-row align="center" class="mx-2">
                                    <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                                        <v-img width="100%" :src="item.image"></v-img>
                                    </v-col>
                                    <v-col cols="12" md="10">
                                        <v-row dense align="center">
                                            <v-col cols="7">
                                                <span v-for="(category, index) in categorys" :key="index">
                                                    <v-chip dark :color="category.color" v-if="category.status === item.status" class="ma-2">
                                                        {{ category.name }}
                                                    </v-chip>
                                                    <v-chip dark :color="category.color" v-if="category.status === 2 && today > dayjs(item.dateRelease).format('YYYY/MM/DD') && today < dayjs(item.dateline).format('YYYY/MM/DD')" class="ma-2">
                                                        {{ category.name }}
                                                    </v-chip>
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-chip dark :color="category.color" v-if="category.status === 3 && today < dayjs(item.dateRelease).format('YYYY/MM/DD') " class="ma-2" v-bind="attrs" v-on="on">
                                                                {{ category.name }}
                                                            </v-chip>
                                                        </template>
                                                        <span>
                                                            1. 活動訊息已送交審核不得自行更改<br>
                                                            2. 本網擁有最終審核活動刊登之權利
                                                        </span>
                                                    </v-tooltip>
                                                    <v-chip dark :color="category.color" v-if="category.status === 4 && today > dayjs(item.dateline).format('YYYY/MM/DD')" class="ma-2">
                                                        {{ category.name }}
                                                    </v-chip>
                                                </span>
                                            </v-col>
                                            <v-col cols="5" class="text-right">
                                                活動日期：{{ item.dateStart }} ~ {{ item.dateline }}
                                            </v-col>
                                            <v-col cols="8" class="title"> {{ item.title }} </v-col>
                                            <v-col cols="12" class="body-1"> {{ item.content }} </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="sendDialog" width="500">
            <v-card>
                <v-card-title class="text-title lighten-2">
                    即將送出審核
                </v-card-title>
                <v-card-text>
                    1. 活動訊息已送交審核不得自行更改<br>
                    2. 本網擁有最終審核活動刊登之權利<br><br>
                    * 圖檔請使用1MB以下之檔案
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange" text
                        @click="send"
                    >
                        同意，並送出審核
                    </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="1000"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          關閉
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>
<script>
    import Editor from '@tinymce/tinymce-vue'
    import { compressAccurately } from 'image-conversion'

    import { mapActions, mapGetters } from 'vuex'
    export default {
        components: {
            editor: Editor
         },
        data () {
            return {
                account: {},
                password: {
                    oldPassword: '',
                    newPassword: '',
                    confirmNewPassword: ''
                },
                snackbar: false,
                snackbarText: '操作成功！',
                today: this.dayjs().format('YYYY/MM/DD'),
                tabs: ['會員資訊', '發表新活動'],
                // tabs: ['發表新活動', '會員資訊', '活動列表'],
                selectedTab: 0,
                confirmRule: false,
                confirmRuleCheckBox: false,
                sendDialog: false,
                showIcon: {
                    oldPassword: false,
                    newPassword: false,
                    confirmPassword: false
                },
                data: {},
                freePriceStatus: true,
                loading: false,
                plugins: [
                    'advlist','autolink','lists','link','image','charmap','preview','anchor','searchreplace','visualblocks', 'code','fullscreen','insertdatetime','media','table', 'paste','help','wordcount'
                ],
                toolbar: 'undo redo | casechange blocks | bold italic backcolor underline forecolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlst checklist outdent indent | removeformat | link media image | code table help preview',
                categorys: [{
                    status: 0,
                    name: '審核中',
                    color: 'deep-orange'
                }, {
                    status: 1,
                    name: '已通過審核',
                    color: 'deep-orange'
                }, {
                    status: 2,
                    name: '正在刊登',
                    color: 'blue'
                }, {
                    status: 3,
                    name: '尚未刊登',
                    color: 'blue'
                }, {
                    status: 4,
                    name: '已下架',
                    color: 'blue'
                }],
                articles: [{
                    id: 1,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'1',
                    dateRelease: '2022/04/20',
                    dateStart: '2022/05/01',
                    dateline: '2022/05/30',
                    status: 0,
                }, {
                    id: 2,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'2',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 3,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'3',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 4,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'2',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/04/30',
                    status: 1,
                }, {
                    id: 5,
                    title: '活動名稱活動名稱活動名稱活動名稱',
                    content: '簡單描述簡單描述簡單描述簡單描述簡單描述簡單描述',
                    image: require('./bg_image_1.png'),
                    type:'1',
                    dateRelease: '2022/02/01',
                    dateStart: '2022/03/01',
                    dateline: '2022/03/30',
                    status: 1,
                }]
            }
        },
        created() {
            this.init()
        },
        computed: {
          ...mapGetters({
            token: 'Auth/getToken',
            // articles: 'Articles/getArticles'
          })
        },
        watch: {
            token() {
                if (!this.token) {
                    this.$router.push({ path: '/home' })
                }
            }
        },
        methods : {
            ...mapActions({
                checkTokenExpiry: 'Auth/checkTokenExpiry',
                logout: 'Auth/logout',
                login: 'Auth/login',
                getAccount: 'Auth/getAccount',
                putAccount: 'Auth/putAccount',
                putPassword: 'Auth/putPassword',
                postArticle: 'Articles/postArticle',
            }),
            async init() {
                await this.checkTokenExpiry()
                this.account = await this.getAccount()
                this.data = {
                    name: '',
                    type: 3,
                    host: '',
                    teacher: '',
                    teacher_image: null,
                    banner: null,
                    describe: '',
                    start_date: '',
                    start_time: '',
                    end_date: '',
                    end_time: '',
                    website: '',
                    contact: '',
                    phone: '',
                    email: '',
                    address_online: true,
                    address_entity: true,
                    address: '',
                    price: [],
                    content: '',
                    created_user: this.account.id
                }
            },
            async updateAccount() {
                await this.putAccount(this.account)
                this.snackbar = true
                this.snackbarText = '操作成功！'
            },
            async updatePassword() {
                const result = await this.putPassword(this.password)
                switch (result.status) {
                    case 101:
                        this.snackbarText = '原密碼錯誤！'
                        break;
                    default:
                        this.snackbarText = '操作成功，請重新登入！'
                        this.password = {
                            oldPassword: '',
                            newPassword: '',
                            confirmNewPassword: ''
                        }

                }
                this.snackbar = true
                this.logout()
            },
            freePrice(value) {
                if (value) {
                    this.data.price = []
                } else {
                    this.data.price = [{
                        name: '',
                        value: null
                    }, {
                        name: '',
                        value: null
                    }, {
                        name: '',
                        value: null
                    }]
                }
            },
            editImageUpload(callback, value ,meta) {
                if (meta.filetype == 'image') {
                    let input = this.$refs.editorImage
                    input.click(value)
                    input.onchange = async function () {
                        var file = await compressAccurately(input.files[0], 800)
                        var reader = new FileReader()
                        reader.onload = function (e) {
                            callback(e.target.result, {
                                alt: file.name
                            });
                        };
                        reader.readAsDataURL(file);
                    };
                }
            },
            async send() {
                this.sendDialog = false
                this.loading = true
                const banner = await this.imageToBase64(this.data.banner)
                if (!banner) {
                    this.snackbar = true
                    this.snackbarText = '活動圖片(海報)有誤，請選擇其他圖片！'
                    return
                }
                this.data.banner = banner

                if (this.data.teacher_image) {
                    const teacherImage = await this.imageToBase64(this.data.teacher_image)
                    if (!teacherImage) {
                        this.snackbar = true
                        this.snackbarText = '講師圖片有誤，請選擇其他圖片！'
                        return
                    }
                    this.data.teacher_image = teacherImage
                }

                const article = await this.postArticle(this.data)

                if (article.id) {
                    this.snackbar = true
                    this.snackbarText = '已送出審核！'
                    this.loading = false
                    this.init()
                } else {
                    this.snackbar = true
                    this.snackbarText = '送出失敗，請稍後再試！'
                    this.loading = false
                }
            },
            async imageToBase64(image) {
                const data = await compressAccurately(image, 800)
                return new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(data)
                    reader.onload = async () => {
                        resolve(reader.result)
                    }
                    reader.onerror = () => reject(false)
                })
            }
        }
    }
</script>

<template>
    <div class="ma-3">
        <v-row>
            <v-col cols="12" md="6">
                <h1>台灣省商業總會理事長 - {{ organize.chairman_name }}</h1>
            </v-col>
            <v-col cols="6" sm="3">
                <v-btn outlined rounded color="deep-orange" to="/tcoc/memberGroup">會員團體名錄</v-btn>
            </v-col>
            <v-col cols="6" sm="3">
                <v-btn outlined rounded color="deep-orange" to="/tcoc/supervisors">第{{organize.supervisors_number}}屆理監事</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center" v-if="chairman">
            <v-col cols="12" sm="3">
                <v-img width="100%" :src="chairman.image"></v-img>
            </v-col>
            <v-col cols="12" sm="5">
                <div class="tinymce" v-html="chairman.introduce"></div>
            </v-col>
            <v-col cols="10">
                <div class="tinymce"  v-html="chairman.content"></div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {}
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            chairman: 'Chairman/getChairman'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              getChairman: 'Chairman/getChairman'
            }),
            init() {
                if(this.organize === ''){
                    this.getOrganize()
                }
                if(this.chairman === ''){
                    this.getChairman()
                }
            }
        }
    }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-3"},[_c('v-tabs',{attrs:{"grow":"","show-arrows":"","color":"deep-orange"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(item,index){return _c('v-tab',{key:index,staticClass:"title",attrs:{"disabled":index > 0 && _vm.account.status !== 1},domProps:{"textContent":_vm._s(item)}})}),1),_c('v-tabs-items',{staticClass:"py-3",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mb-3",attrs:{"label":"","color":"#17a2b8","dark":""}},[_c('h3',[_vm._v("個人資料")])]),_c('v-spacer'),(_vm.token)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"grey"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-logout ")])],1)]}}],null,true)},[_c('span',[_vm._v(" 登出 ")])]):_vm._e()],1),(_vm.account.status === 1)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","label":"電子信箱","hide-details":"auto"},model:{value:(_vm.account.email),callback:function ($$v) {_vm.$set(_vm.account, "email", $$v)},expression:"account.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"所屬團體/中小企業","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"所屬團體/中小企業","hide-details":"auto"},model:{value:(_vm.account.group_name),callback:function ($$v) {_vm.$set(_vm.account, "group_name", $$v)},expression:"account.group_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"姓名","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"姓名","hide-details":"auto"},model:{value:(_vm.account.name),callback:function ($$v) {_vm.$set(_vm.account, "name", $$v)},expression:"account.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"聯絡電話","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"聯絡電話","hide-details":"auto"},model:{value:(_vm.account.phone),callback:function ($$v) {_vm.$set(_vm.account, "phone", $$v)},expression:"account.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"dark":!invalid,"color":"deep-orange","disabled":invalid},on:{"click":_vm.updateAccount}},[_vm._v("送出")])],1)],1):_c('span',[_vm._v("您需完成變更密碼後，才能進行個人資料維護及文章發表喔！")])],1)],1)]}}])}),(!_vm.account.sso_type)?_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"mt-5",attrs:{"outlined":""}},[_c('v-card-text',[_c('v-chip',{staticClass:"mb-3",attrs:{"label":"","color":"#17a2b8","dark":""}},[_c('h3',[_vm._v("變更密碼")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"原密碼","rules":"required|alpha_dash|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showIcon.oldPassword ? 'mdi-eye' : 'mdi-eye-off',"placeholder":"請輸入舊密碼","type":_vm.showIcon.oldPassword ? 'text' : 'password',"label":"原密碼","hide-details":"auto","outlined":"","dense":""},on:{"click:append":function($event){_vm.showIcon.oldPassword = !_vm.showIcon.oldPassword}},model:{value:(_vm.password.oldPassword),callback:function ($$v) {_vm.$set(_vm.password, "oldPassword", $$v)},expression:"password.oldPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"新密碼","rules":"required|alpha_dash|min:8|confirmedA:confirmPassword","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showIcon.newPassword ? 'mdi-eye' : 'mdi-eye-off',"placeholder":"請輸入8~18位數新密碼","type":_vm.showIcon.newPassword ? 'text' : 'password',"label":"新密碼","hide-details":"auto","outlined":"","dense":""},on:{"click:append":function($event){_vm.showIcon.newPassword = !_vm.showIcon.newPassword}},model:{value:(_vm.password.newPassword),callback:function ($$v) {_vm.$set(_vm.password, "newPassword", $$v)},expression:"password.newPassword"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"確認新密碼","rules":"required|alpha_dash|min:8|confirmedB:password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"append-icon":_vm.showIcon.confirmPassword ? 'mdi-eye' : 'mdi-eye-off',"placeholder":"請再輸入一次新密碼","type":_vm.showIcon.confirmPassword ? 'text' : 'password',"label":"確認新密碼","hide-details":"auto","outlined":"","dense":""},on:{"click:append":function($event){_vm.showIcon.confirmPassword = !_vm.showIcon.confirmPassword}},model:{value:(_vm.password.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.password, "confirmNewPassword", $$v)},expression:"password.confirmNewPassword"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"dark":!invalid,"color":"deep-orange","disabled":invalid},on:{"click":_vm.updatePassword}},[_vm._v("送出")])],1)],1)],1)],1)]}}],null,false,4223425990)}):_vm._e()],1),_c('v-tab-item',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"outlined":""}},[(_vm.confirmRule)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"活動課程標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","outlined":"","label":"活動課程標題","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"主辦單位","rules":"required|alpha|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","outlined":"","label":"主辦單位","error-messages":errors},model:{value:(_vm.data.host),callback:function ($$v) {_vm.$set(_vm.data, "host", $$v)},expression:"data.host"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"活動圖片","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"outlined":"","accept":"image/*","label":"活動圖片(海報)","error-messages":errors},model:{value:(_vm.data.banner),callback:function ($$v) {_vm.$set(_vm.data, "banner", $$v)},expression:"data.banner"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('validation-provider',{attrs:{"name":"活動課程描述","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"500","outlined":"","label":"活動課程描述","error-messages":errors},model:{value:(_vm.data.describe),callback:function ($$v) {_vm.$set(_vm.data, "describe", $$v)},expression:"data.describe"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('validation-provider',{attrs:{"name":"活動網址","rules":"url|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","outlined":"","label":"活動網址","error-messages":errors},model:{value:(_vm.data.website),callback:function ($$v) {_vm.$set(_vm.data, "website", $$v)},expression:"data.website"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"聯絡人","rules":"required|alpha|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","outlined":"","label":"聯絡人","error-messages":errors},model:{value:(_vm.data.contact),callback:function ($$v) {_vm.$set(_vm.data, "contact", $$v)},expression:"data.contact"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"聯絡電話","rules":"required|phone|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","label":"聯絡電話","outlined":"","error-messages":errors},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"45","outlined":"","label":"Email","error-messages":errors},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"講師","rules":"required|alpha|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"30","outlined":"","label":"講師","error-messages":errors},model:{value:(_vm.data.teacher),callback:function ($$v) {_vm.$set(_vm.data, "teacher", $$v)},expression:"data.teacher"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"講師圖片"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"outlined":"","accept":"image/*","label":"講師圖片","error-messages":errors},model:{value:(_vm.data.teacher_image),callback:function ($$v) {_vm.$set(_vm.data, "teacher_image", $$v)},expression:"data.teacher_image"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"起始日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"date","label":"起始日","error-messages":errors},model:{value:(_vm.data.start_date),callback:function ($$v) {_vm.$set(_vm.data, "start_date", $$v)},expression:"data.start_date"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"起始時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"time","label":"起始時間","error-messages":errors},model:{value:(_vm.data.start_time),callback:function ($$v) {_vm.$set(_vm.data, "start_time", $$v)},expression:"data.start_time"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"結束日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"date","label":"結束日","error-messages":errors},model:{value:(_vm.data.end_date),callback:function ($$v) {_vm.$set(_vm.data, "end_date", $$v)},expression:"data.end_date"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"結束時間","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"time","label":"結束時間","error-messages":errors},model:{value:(_vm.data.end_time),callback:function ($$v) {_vm.$set(_vm.data, "end_time", $$v)},expression:"data.end_time"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("活動地點 (若有實體活動地點，請提供地址)")]),_c('v-checkbox',{attrs:{"hide-details":"","label":"線上活動"},model:{value:(_vm.data.address_online),callback:function ($$v) {_vm.$set(_vm.data, "address_online", $$v)},expression:"data.address_online"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"實體活動"},model:{value:(_vm.data.address_entity),callback:function ($$v) {_vm.$set(_vm.data, "address_entity", $$v)},expression:"data.address_entity"}}),_c('validation-provider',{attrs:{"name":"實體活動地點","rules":"alpha_dash|max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.address_entity),expression:"data.address_entity"}],staticClass:"mt-2",attrs:{"counter":"45","outlined":"","label":"實體活動地點","color":"primary","error-messages":errors},model:{value:(_vm.data.address),callback:function ($$v) {_vm.$set(_vm.data, "address", $$v)},expression:"data.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("費用資訊")]),_c('span',{staticClass:"subtitle-2"},[_vm._v("請填寫價位名稱及金額")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-checkbox',{attrs:{"hide-details":"","label":"免費活動"},on:{"change":_vm.freePrice},model:{value:(_vm.freePriceStatus),callback:function ($$v) {_vm.freePriceStatus=$$v},expression:"freePriceStatus"}})],1),(!_vm.freePriceStatus)?_c('div',{staticClass:"mt-5"},[(_vm.data.price[0])?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"票種","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","placeholder":"例如：原價","error-messages":errors},model:{value:(_vm.data.price[0].name),callback:function ($$v) {_vm.$set(_vm.data.price[0], "name", $$v)},expression:"data.price[0].name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"售價","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","prepend-inner-icon":"mdi-currency-usd","placeholder":"1500","error-messages":errors},model:{value:(_vm.data.price[0].value),callback:function ($$v) {_vm.$set(_vm.data.price[0], "value", $$v)},expression:"data.price[0].value"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.data.price[1])?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"票種","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","placeholder":"例如：原價","error-messages":errors},model:{value:(_vm.data.price[1].name),callback:function ($$v) {_vm.$set(_vm.data.price[1], "name", $$v)},expression:"data.price[1].name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"售價","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","prepend-inner-icon":"mdi-currency-usd","placeholder":"1500","error-messages":errors},model:{value:(_vm.data.price[1].value),callback:function ($$v) {_vm.$set(_vm.data.price[1], "value", $$v)},expression:"data.price[1].value"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.data.price[2])?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"票種","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","placeholder":"例如：原價","error-messages":errors},model:{value:(_vm.data.price[2].name),callback:function ($$v) {_vm.$set(_vm.data.price[2], "name", $$v)},expression:"data.price[2].name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"售價","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","color":"primary","prepend-inner-icon":"mdi-currency-usd","placeholder":"1500","error-messages":errors},model:{value:(_vm.data.price[2].value),callback:function ($$v) {_vm.$set(_vm.data.price[2], "value", $$v)},expression:"data.price[2].value"}})]}}],null,true)})],1)],1):_vm._e()],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pa-3",attrs:{"align":"end"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v("活動介紹")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"editorImage",attrs:{"type":"file","accept":"image/*"}})]),_c('editor',{attrs:{"api-key":"hzfavngfeqhvixw4riq3hlw5b6oliak1djzodq4m52c25jk6","init":{
                                        height: 800,
                                        plugins: _vm.plugins,
                                        toolbar: _vm.toolbar,
                                        file_picker_types: 'image',
                                        paste_data_images: true,
                                        powerpaste_allow_local_images: true,
                                        image_advtab: true,
                                        image_class_list: [{title: 'Responsive', value: 'img-responsive'}],
                                        file_picker_callback: function (callback, value, meta) { _vm.editImageUpload(callback, value ,meta)  },
                                    }},model:{value:(_vm.data.content),callback:function ($$v) {_vm.$set(_vm.data, "content", $$v)},expression:"data.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{on:{"click":function($event){_vm.confirmRule = _vm.confirmRuleCheckBox = false}}},[_vm._v("取消")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":invalid,"loading":_vm.loading},on:{"click":function($event){_vm.sendDialog = true}}},[_vm._v("送出審核")])],1)])],1)],1):_c('v-card-text',[_c('h2',[_vm._v("台灣商會聯合資訊網受理網路會員免費刊登活動訊息申請須知：")]),_c('br'),_c('div',{staticClass:"subtitle-1"},[_vm._v(" 1. 活動訊息至遲應於開辦前三天提出申請。"),_c('br'),_vm._v(" 2. 活動內容應與商業服務有關之活動、講座或課程。"),_c('br'),_vm._v(" 3. 活動訊息刊登與否需經本網審查，如有違背公序良俗、虛偽不實、違反政府法令規定者，本網有權拒絕刊登或撤除。"),_c('br'),_vm._v(" 4. 活動訊息刊登後，參與者若發生任何爭議，概由刊登者與主辦單位自行與參與者依循正當法律途徑解決，本會歉難負責。 ")]),_c('div',{staticClass:"text-right"},[_c('v-checkbox',{attrs:{"label":"我同意上述規定"},model:{value:(_vm.confirmRuleCheckBox),callback:function ($$v) {_vm.confirmRuleCheckBox=$$v},expression:"confirmRuleCheckBox"}}),_c('v-btn',{attrs:{"disabled":!_vm.confirmRuleCheckBox},on:{"click":function($event){_vm.confirmRule = true}}},[_vm._v("開始刊登")])],1)])],1)]}}])})],1),_c('v-tab-item',[_c('v-row',{attrs:{"dense":"","justify":"end","align":"center"}},_vm._l((_vm.articles),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-3",attrs:{"elevation":hover ? 5 : 2,"color":hover ? 'orange lighten-4': 'orange lighten-5'}},[_c('v-row',{staticClass:"mx-2",attrs:{"align":"center"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"width":"100%","src":item.image}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"7"}},_vm._l((_vm.categorys),function(category,index){return _c('span',{key:index},[(category.status === item.status)?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":category.color}},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e(),(category.status === 2 && _vm.today > _vm.dayjs(item.dateRelease).format('YYYY/MM/DD') && _vm.today < _vm.dayjs(item.dateline).format('YYYY/MM/DD'))?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":category.color}},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [(category.status === 3 && _vm.today < _vm.dayjs(item.dateRelease).format('YYYY/MM/DD') )?_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"dark":"","color":category.color}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" 1. 活動訊息已送交審核不得自行更改"),_c('br'),_vm._v(" 2. 本網擁有最終審核活動刊登之權利 ")])]),(category.status === 4 && _vm.today > _vm.dayjs(item.dateline).format('YYYY/MM/DD'))?_c('v-chip',{staticClass:"ma-2",attrs:{"dark":"","color":category.color}},[_vm._v(" "+_vm._s(category.name)+" ")]):_vm._e()],1)}),0),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}},[_vm._v(" 活動日期："+_vm._s(item.dateStart)+" ~ "+_vm._s(item.dateline)+" ")]),_c('v-col',{staticClass:"title",attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-col',{staticClass:"body-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.content)+" ")])],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.sendDialog),callback:function ($$v) {_vm.sendDialog=$$v},expression:"sendDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-title lighten-2"},[_vm._v(" 即將送出審核 ")]),_c('v-card-text',[_vm._v(" 1. 活動訊息已送交審核不得自行更改"),_c('br'),_vm._v(" 2. 本網擁有最終審核活動刊登之權利"),_c('br'),_c('br'),_vm._v(" * 圖檔請使用1MB以下之檔案 ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"deep-orange","text":""},on:{"click":_vm.send}},[_vm._v(" 同意，並送出審核 ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":"1000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" 關閉 ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
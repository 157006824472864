import axios from './axios.js'

export default {
    namespaced: true,
    state: {
        history: [],
        nowNumber: null
    },
    actions: {
        getHistory ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/history`).then(response => {
                    commit('setHistory', response)
                    if (response.length > 0) {
                        commit('setNowNumber', response[response.length - 1].number)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOneHistory ({state},data) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/history/${data.id}`).then(response => {
                    resolve(response, state)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        setHistory(state, data){
            data.forEach((item) => {
                item.image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.image}`
            })
            state.history = data
        },
        setNowNumber(state, data){
            state.nowNumber = data
        }
    },
    getters: {
        getHistory: state => {
          return state.history
        },
    }
}

<template>
    <v-row justify="space-around">
        <tool-form-forget-password-dialog ref="formForgetPassword"></tool-form-forget-password-dialog>
        <v-col cols="auto">
            <v-dialog max-width="600" v-model="diologStatus">
                <validation-observer v-slot="{ invalid }">
                    <v-card>
                        <v-toolbar color="deep-orange" dark>
                            <h3>{{ Title }}</h3>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="diologStatus = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="mt-3 pa-3">
                            <v-row dense justify="center">
                                <v-col cols="12" md="8">
                                    <div v-show="apiError" class="red--text pb-3 text-center"> {{ errorMsg }} </div>
                                    <validation-provider name="電子信箱" rules="required|email" v-slot="{ errors }">
                                        <v-text-field  outlined dense :error-messages="errors"
                                          v-model="data.email" label="電子信箱"
                                          hide-details="auto"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <validation-provider name="密碼" rules="required|alpha_dash|min:8" v-slot="{ errors }">
                                        <v-text-field :error-messages="errors"
                                            v-model="data.password"
                                            :append-icon="eyeIcon ? 'mdi-eye' : 'mdi-eye-off'"
                                            :type="eyeIcon ? 'text' : 'password'"
                                            label="密碼" hide-details="auto"
                                            outlined dense
                                            @click:append="eyeIcon = !eyeIcon"
                                        ></v-text-field>
                                    </validation-provider>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-row justify="space-between" dense>
                                        <v-col cols="4">
                                            <v-btn text class="pa-0" @click="openThirdTarget()">還沒有帳號嗎?立即註冊!</v-btn>
                                        </v-col>
                                        <v-col cols="3" class="text-end">
                                            <v-btn text class="pa-0" color="grey" @click="forgetPasswordFunction()">忘記密碼</v-btn>
                                        </v-col>
                                    </v-row>
                                    <div class="d-flex">
                                        <p>或透過社群帳號登入
                                            <v-btn icon @click="fbLogin">
                                                <v-icon large color="blue darken-3"> mdi-facebook </v-icon>
                                            </v-btn>
                                        </p>
                                        <div id="googleButton"></div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="justify-end mt-2">
                          <v-btn @click="diologStatus = false">關閉</v-btn>
                          <v-btn color="deep-orange" :dark="!invalid" :loading="loading" @click="save()" :disabled="invalid"> 登入 </v-btn>
                        </v-card-actions>
                  </v-card>
              </validation-observer>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import ToolFormForgetPasswordDialog from '@/components/ToolFormForgetPasswordDialog'
import { mapActions } from 'vuex'
export default {
    components: {
        'tool-form-forget-password-dialog': ToolFormForgetPasswordDialog,
    },
  data () {
    return {
      diologStatus: false,
      eyeIcon: false,
      Title: '註冊/登入',
      thirdFunction: null,
      loading: false,
      data: {
          email: '',
          password: ''
      },
      errorMsg: '',
      apiError: false
    }
  },
  methods: {
      ...mapActions({
          login: 'Auth/login',
          loginFacebook: 'SSO/loginFacebook'
      }),
      setOpen (target) {
          this.diologStatus = true
          this.data = {
              email: '',
              password: ''
          }
          this.thirdFunction = target
          setTimeout(() => {
              /* global google */
              google.accounts.id.renderButton(
                  document.getElementById('googleButton'),
                  {
                      type: 'icon',
                      size: 'large',
                      text: 'signin_with',
                      shape: 'pill',
                      logo_alignment: 'center',
                      width: 250
                  }
              )
          }, 1)
      },
      setClose(){
          this.diologStatus = false
      },
      async save () {
          this.errorMsg = ''
          this.apiError = false
          const response = await this.login(this.data)
          switch (response.status) {
              case 101:
                  this.errorMsg = '電子信箱尚未註冊'
                  this.apiError = true
                  break
              case 102:
                  this.errorMsg = '密碼有誤'
                  this.apiError = true
                  break
              case 0:
                  this.errorMsg = '很抱歉！因安全疑慮您的帳號暫時無法使用，請洽本會'
                  this.apiError = true
                  break
              default:
                this.diologStatus = false
                this.$router.push({ path: '/userActivity' })
          }
      },
      openThirdTarget() {
          this.diologStatus = false
          this.thirdFunction(this.setOpen)
      },
      forgetPasswordFunction() {
          this.diologStatus = false
          this.$refs.formForgetPassword.setOpen()
      },
      fbLogin() {
          this.loginFacebook()
          this.diologStatus = false
      }
  }
}
</script>
<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>

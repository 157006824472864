import axios from './axios.js'
import { encode, decode } from 'html-entities'
import dayjs from 'dayjs'
export default {
    namespaced: true,
    state: {
        articles: '',
        article: ''
    },
    actions: {
        getArticles ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/articles?published[0]=1&alive=true&audit[0]=1`).then(response => {
                    commit('setArticles', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getArticle ({commit}, id) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/articles/${id}`).then(response => {
                    response.teacher_image = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${response.teacher_image}`
                    response.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${response.banner}`
                    response.content = decode(response.content)
                    commit('setArticle', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        postArticle ({dispatch}, data) {
            data.content = encode(data.content)
            return new Promise((resolve, reject) => {
                axios.post(`/api/articlesByUser/byUser`, data).then(response => {
                    dispatch('getArticles')
                    resolve(response)
                }).catch(() => {
                    reject(false)
                })
            })
        }
    },
    mutations: {
        setArticles(state, data) {
            data.forEach((item) => {
                item.content = decode(item.content)
                item.start_date = dayjs(item.start_date).format('YYYY/MM/DD')
                item.end_date = dayjs(item.end_date).format('YYYY/MM/DD')
                item.teacher_image = item.teacher_image ? `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.teacher_image}`: ''
                item.banner = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.banner}`
            })

            state.articles = data
        },
        setArticle(state, data) {
            state.article = data
        }
    },
    getters: {
        getArticles: state => {
          return state.articles
        },
        getArticle: state => {
          return state.article
        }
    }
}

import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        edms: '',
        edmsYears: []
    },
    actions: {
        getEdms ({commit}, params) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/edms`, { params }).then(response => {
                    commit('setEdms', response)
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        getEdm ({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/edms/${data.id}`).then(response => {
                    commit()
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setEdms(state, data) {
            data.forEach((item) => {
                item.path = item.url
                item.url = `${process.env.VUE_APP_FILE_SERVER_ADDRESS}${item.url}`
                state.edmsYears.push(item.year)
            })
            state.edms = data
        }
    },
    getters: {
        getEdms: state => {
            return state.edms
        },
        getEdmsYears: state => {
            return state.edmsYears
        },
    }
}

<template>
    <div>
        <v-carousel cycle show-arrows-on-hover hide-delimiters :height="$vuetify.breakpoint.mdAndUp ? '70vh': '50vh'" v-if="activitiesOnlyTop">
            <v-carousel-item
                v-for="(item,i) in activitiesOnlyTop"
                :key="`carousel${i}`" :to="`/tcocDynamic/${item.id}`"
                :src="item.banner" reverse-transition="fade-transition"
            >
                <v-container fill-height fluid class="pa-0">
                    <v-layout align-end>
                        <v-flex xs12 style="background-color:rgba(249, 151, 87);">
                            <div class="headline mx-3 white--text"> {{ item.name }} </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-carousel-item>
        </v-carousel>
        <v-row dense class="mt-1" v-if="activities && articles">
            <!-- 商會動態 -->
            <v-col cols="12" class="text-center">
                <v-btn rounded class="title" color="primary" to="/tcocDynamic/"> 商 會 動 態 </v-btn>
            </v-col>
            <v-col cols="12" md="3" v-for="index in 4" :key="`tcocDynamic${index}`">
                <v-hover v-slot="{ hover }">
                    <v-card height="100%" max-width="100%"
                        v-if="activities[index-1]" :to="`/tcocDynamic/${activities[index-1].id}`"
                    >
                        <v-img :aspect-ratio="18/9" :src="activities[index-1].banner">
                            <v-expand-transition>
                                <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out primary v-card--reveal text-title white--text"
                                    style="height: 100;"
                                >
                                    {{ activities[index-1].name }}
                                </div>
                            </v-expand-transition>
                        </v-img>
                        <div class="title pa-3">
                            {{ _limit(activities[index-1].describe, 32, '') }}{{activities[index-1].describe.length > 32 ? '...': ''}}
                        </div>
                    </v-card>
                </v-hover>
            </v-col>
            <!-- 活動課程 -->
            <v-col cols="12" class="text-center">
                <v-btn rounded class="title" color="primary" to="/activities/"> 活 動 課 程 </v-btn>
            </v-col>
            <v-col cols="12">
                <v-row dense v-if="articles.length > 0">
                    <v-col cols="12" md="3" v-for="index in 4" :key="`activities${index}`">
                        <v-hover v-slot="{ hover }">
                            <v-card height="100%" width="100%" v-if="articles[index-1]" :to="`/activities/${articles[index-1].id}`">
                                <v-card-title>
                                    <v-row d-flex>
                                        <v-avatar size="56" color="blue-grey">
                                            <img :src="articles[index-1].teacher_image" v-if="articles[index-1].teacher_image">
                                        </v-avatar>
                                        <span class="ml-3 text-md-subtitle-1 text-sm-body-2">
                                            {{ articles[index-1].start_date }}~{{ articles[index-1].end_date }}<br>
                                            <b>{{ articles[index-1].teacher }}</b>
                                        </span>
                                    </v-row>
                                </v-card-title>
                                <div class="title">
                                    <v-img :src="articles[index-1].banner" :aspect-ratio="29/14">
                                        <v-expand-transition>
                                            <div
                                                v-if="hover"
                                                class="d-flex transition-fast-in-fast-out primary v-card--reveal text-title white--text"
                                                style="height: 100;"
                                            >
                                                {{ articles[index-1].name }}
                                            </div>
                                        </v-expand-transition>
                                    </v-img>
                                </div>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
                <v-card color="blue-grey lighten-5" v-else>
                    目前沒有舉行中的活動課程！
                </v-card>
            </v-col>
            <v-col cols="6" md="2" v-for="(item, index) in websites" :key="`websites${index}`">
                <a :href="item.url" target="_blank">
                    <img :src="item.src" width="100%" class="pt-2 imgTest">
                </a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import { limit } from 'stringz';
  export default {
      data () {
          return {
              websites: [{
                  src: require('../../footers/website1.jpeg'),
                  url: '/#/tcocGroupActivities'
              }, {
                  src: require('../../footers/website6.jpeg'),
                  url: '/#/tcocTaxWorkshop'
              }, {
                  src: require('../../footers/website2.jpeg'),
                  url: 'https://www.moeasmea.gov.tw/masterpage-tw'
              }, {
                  src: require('../../footers/website3.jpeg'),
                  url: 'https://www.moi.gov.tw/'
              }, {
                  src: require('../../footers/website4.jpeg'),
                  url: 'https://www.trade.gov.tw/'
              }, {
                  src: require('../../footers/website5.jpeg'),
                  url: 'https://www.taitra.org.tw/'
              }]
          }
      },
      computed: {
          ...mapGetters({
              activities: 'Activities/getActivities',
              activitiesOnlyTop: 'Activities/getActivitiesOnlyTop',
              articles: 'Articles/getArticles'
          }),
          getSecActivities() {
              const result = []
              const length = this.activities.length > 5 ? 5 : this.activities.length
              let i = this.$vuetify.breakpoint.mdAndUp ? 1 : 0
              for (i ; i < length ; i++) {
                  result.push(this.activities[i])
              }
              return result
          },
          getSecArticles() {
              const result = []
              const length = this.articles.length > 4 ? 4 : this.articles.length
              for (let i = 1 ; i < length ; i++) {
                  result.push(this.articles[i])
              }
              return result
          },
          getDecLength() {
              let length = 41
              if (this.$vuetify.breakpoint.lgOnly) {
                  length = 100
              }
              if (this.$vuetify.breakpoint.mdOnly) {
                  length = 44
              }
              return length
          },
          _limit() {
              return limit
          }
      },
      created() {
          this.init()
      },
      methods:{
          ...mapActions({
              getActivities: 'Activities/getActivities',
              getActivitiesOnlyTop: 'Activities/getActivitiesOnlyTop',
              getArticles: 'Articles/getArticles'
          }),
          async init() {
              if (!this.activities) {
                  await this.getActivities()
              }
              if (!this.activitiesOnlyTop) {
                  await this.getActivitiesOnlyTop()
              }
              if (!this.articles) {
                  await this.getArticles()
              }
          }
      }
  }
</script>
<style scoped>
    .v-card {
      transition: opacity .4s ease-in-out;
    }

    .v-card.on-hover {
      opacity: 0.6;
     }
</style>

<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="12">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center title">No.</th>
                                <th class="text-center title">公會名稱</th>
                                <th class="text-center title" v-if="$vuetify.breakpoint.mdAndUp">No.</th>
                                <th class="text-center title" v-if="$vuetify.breakpoint.mdAndUp">公會名稱</th>
                            </tr>
                        </thead>
                        <tbody v-if="$vuetify.breakpoint.mdAndUp">
                            <tr v-for="(item, index) in memberGroups" :key="index">
                                <td class="title" v-if="index % 2 === 0">{{ item.id }}</td>
                                <td v-if="index % 2 === 0">
                                    <v-btn text :to="`/tcoc/memberGroup/${item.id}`" class="title" :disabled="!item.pay_status">
                                        {{ item.name }}
                                    </v-btn>
                                </td>
                                <td class="title" v-if="memberGroups[index+1] && (index+1) % 2 > 0">{{ memberGroups[index+1].id }}</td>
                                <td v-if="memberGroups[index+1] && (index+1) % 2 > 0">
                                    <v-btn text :to="`/tcoc/memberGroup/${memberGroups[index+1].id}`" class="title" :disabled="!memberGroups[index+1].pay_status">
                                        {{ memberGroups[index+1].name }}
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-for="(item, index) in memberGroups" :key="index">
                                <td class="title">{{ item.id }}</td>
                                <td>
                                    <v-btn text :to="`/tcoc/memberGroup/${item.id}`" class="title" :disabled="!item.pay_status">
                                        {{ item.name }}
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
            </v-simple-table>
            <!-- <v-data-table v-else
                :headers="[{value: 'name', sortable: false}]"
                :items="memberGroups" :items-per-page="-1"
                class="elevation-1"
            ></v-data-table> -->
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {}
        },
        computed: {
          ...mapGetters({
                memberGroups: 'MemberGroups/getMemberGroups',
                basicGroups: 'MemberGroups/getBasicGroups',
            })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getMemberGroups: 'MemberGroups/getMemberGroups',
              getBasicGroups: 'MemberGroups/getBasicGroups',
            }),
            async init() {
                if(this.memberGroups === ''){
                    await this.getMemberGroups()
                }
                if(this.basicGroups === ''){
                    await this.getBasicGroups()
                }
            }
        }
    }
</script>

<template>
    <div class="ma-3" v-if="organize">
        <v-row justify="center">
            <v-col cols="12" md="3">
                <h1>關於省商會</h1>
            </v-col>
            <v-col cols="12" md="8">
                <v-row class="text-center">
                    <v-col cols="6" sm="4">
                        <v-btn outlined rounded color="deep-orange" to="/tcoc/chairman">理事長{{ organize.chairman_name }}</v-btn>
                    </v-col>
                    <v-col cols="6" sm="4">
                        <v-btn outlined rounded color="deep-orange" to="/tcoc/memberGroup">會員團體名錄</v-btn>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-btn outlined rounded color="deep-orange" to="/tcoc/supervisors">第{{ organize.chairman_number }}屆理監事</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="10">
                <v-card flat color="#F6F5FC">
                    <v-card-text class="title tinymce" v-html="organize.describe"></v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-tabs grow show-arrows color="deep-orange" v-model="selectedTab">
                    <v-tab v-for="(item, index) in tabs" :key="index" v-text="item.name">  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="selectedTab" class="pa-3">
                    <v-tab-item>
                        <v-row align="center">
                            <v-col cols="12" md="7">
                                <v-img :src="organize.image"></v-img>
                            </v-col>
                            <v-col cols="12" md="5" class="tinymce" v-html="organize.content"></v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row v-if="administrative">
                            <v-col cols="12" md="6">
                                <v-img width="100%" :src="administrative.image"></v-img>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-card flat color="#F6F5FC"  class="pa-3">
                                    <div class="tinymce" v-html="administrative.content"></div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab" v-if="$vuetify.breakpoint.mdAndUp">
                            <div class="timeline">
                                <div class="timeline-row" v-for="(item, key) in history" :key="key">
                                    <div class="timeline-time"> {{item.title}} <br>
                                        <small> {{ item.content }} </small>
                                    </div>
                                    <div class="timeline-content">
                                        <v-img class="img-fluid" :src="item.image"></v-img>
                                    </div>
                                </div>
                                <div class="arrow"></div>
                            </div>
                        </div>
                        <div v-else>
                            <v-row v-for="(item, key) in history" :key="key">
                                <v-col cols="12">
                                    <div class="title">
                                        {{ item.title }}
                                    </div>
                                     {{ item.content }}
                                </v-col>
                                <v-col cols="10" sm="5"  class="text-center">
                                    <v-img width="100%" :src="item.image"></v-img>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row justify="center">
                            <v-col cols="10" md="6">
                                <v-img v-if="organize.masters" :src="organize.masters"></v-img>
                            </v-col>
                            <v-col cols="10" md="6">
                                <v-card flat color="#F6F5FC" class="body">
                                    <v-card-text class="body tinymce">
                                        <ul>
                                            <li>林熊徵先生於35年9月16日當選首屆理事長未久，即於11月間謝世，並補選陳啟清先生繼任；38年3月23日仍由陳啟清先生續任第二屆理事長，39年陳啟清先生請辭理事長改就本會顧問，並改選常務理事黃添樑先生接任；40年4月18日黃添樑先生再當選連任第三屆理事長。</li>
                                            <li>42年11月26日張祥傳先生當選第四屆理事長，45年3月31日張祥傳先生再當選連任第五屆理事長。</li>
                                            <li>47年11月26日謝成源先生當選第六屆理事長，50年9月21日謝成源先生再當選連任第七屆理事長。</li>
                                            <li>53年1月8日黃成金先生當選第八屆理事長，到任未久即辭卸理事長並改選由謝清雲先生接任。</li>
                                            <li>55年5月28日張祥傳先生當選第九屆理事長。</li>
                                            <li>57年9月6日黃綿綿先生當選第十屆理事長，60年6月25日黃綿綿先生再當選連任第十一屆理事長，62年9月21日黃綿綿先生再當選本會更名改制後的第十二屆理事長，65年10月8日黃綿綿先生再當選連任第十三屆理事長。</li>
                                            <li>68年10月3日陳新發先生當選第十四屆理事長，72年3月31日陳新發先生再當選連任第十五屆理事長。</li>
                                            <li>75年4月30日白炳輝先生當選第十六屆理事長。</li>
                                            <li>78年6月2日張平沼先生當選第十七屆理事長，81年5月28日張平沼先生再當選連任第十八屆理事長。</li>
                                            <li>84年5月26日莊隆昌先生當選第十九屆理事長，87年6月2日莊隆昌先生再當選連任本會第二十屆理事長。</li>
                                            <li>91年10月2日張平沼先生當選第二十一屆理事長，94年12月5日再當選連任第二十二屆理事長。</li>
                                            <li>98年5月12日張榮味理事長當選第二十三屆理事長，104年3月30日再當選連任第二十四屆理事長。</li>
                                            <li>107年3月27日蔡國洲理事長當選第二十五屆理事長，110年11月2日再當選連任第二十六屆理事長。</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
        data () {
            return {
                tabs: [{
                    name: '組織架構'
                }, {
                    name: '行政系統'
                }, {
                    name: '歷史沿革'
                }, {
                    name: '歷屆理事長'
                }],
                selectedTab: 0
            }
        },
        computed: {
          ...mapGetters({
            organize: 'Organize/getOrganize',
            administrative: 'Administrative/getAdministrative',
            history: 'History/getHistory'
          })
        },
        created() {
            this.init()
        },
        methods:{
            ...mapActions({
              getOrganize: 'Organize/getOrganize',
              getAdministrative: 'Administrative/getAdministrative',
              getHistory: 'History/getHistory',
            }),
            init() {
                if(this.organize === ''){
                    this.getOrganize()
                }
                if(this.administrative === ''){
                    this.getAdministrative()
                }
                if(this.history.length === 0){
                    this.getHistory()
                }
            }
        }
    }
</script>
<style media="screen">
.timeline {
    position: relative;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 auto 1rem auto;
    overflow: hidden;
}
.timeline:after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -2px;
    border-right: 5px inset #0047b1;
    height: 99%;
    display: block;
}

.timeline-row {
    padding-left: 50%;
    position: relative;
    margin-bottom: 30px;
}

.timeline .arrow {
    border: solid #0047b1;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 11px;
    margin-left: 49.1%;
    transform: rotate(45deg);
}

.timeline-row .timeline-time {
    position: absolute;
    right: 50%;
    top: 15px;
    text-align: right;
    margin-right: 20px;
    color: #000000;
    font-size: 1.5rem;
    height:100%;
}
.timeline-row .timeline-time small {
    display: block;
    font-size: 1rem;
    height:80%;
    text-align: left;
    display: flex;          /* ★ */
    align-items: center;    /* ★ */
    font-size: 1rem;
    margin: 15px;
}
.timeline-row .timeline-content {
    position: relative;
    padding: 20px 30px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-clip: content-box;
}
.timeline-row .timeline-content:before {
    content: "";
    position: absolute;
    top: 20px;
    right: -50px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    z-index: 10;
    background: #f58a44;
    border: 2px dashed #4b546f;
}
.timeline-row .timeline-content h4 {
    margin: 0 0 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 150%;
}
.timeline-row .timeline-content .thumbs {
    margin-bottom: 20px;
    display: flex;
}
.timeline-row .timeline-content .thumbs img {
    margin: 5px;
    max-width: 60px;
}
.timeline-row .timeline-content .badge {
    color: #ffffff;
    background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
}
.timeline-row:nth-child(even) .timeline-content {
    margin-left: 40px;
    text-align: left;
}
.timeline-row:nth-child(even) .timeline-content:after {
    left: -8px;
    right: initial;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-135deg);
}
.timeline-row:nth-child(even) .timeline-content:before {
    left: -50px;
    right: initial;
}
.timeline-row:nth-child(odd) {
    padding-left: 0;
    padding-right: 50%;
}
.timeline-row:nth-child(odd) .timeline-time {
    right: auto;
    left: 50%;
    text-align: left;
    margin-right: 0;
    margin-left: 20px;
    height:100%;
}
.timeline-row:nth-child(odd) .timeline-time small {
    height:80%;
    display: flex;          /* ★ */
    align-items: center;    /* ★ */
    font-size: 1rem;
    margin: 15px;
}
.timeline-row:nth-child(odd) .timeline-content {
    margin-right: 40px;
}
.timeline-row:nth-child(odd) .timeline-content:after {
    right: -8px;
    border-left: 0;
    border-bottom: 0;
    border: solid black;
}
</style>

import axios from './axios.js'
export default {
    namespaced: true,
    state: {
        token: null,
        expiry: null,
        userId: null
    },
    actions: {
        login ({commit}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/login`, {
                    email: data.email,
                    password: data.password
                }).then(response => {
                    if (response.status === 1 || response.status === 2) {
                        commit('setTokenAndExpiry', response)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        ssoSignUp ({dispatch}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/ssoSignUp`, data
                ).then(response => {
                    if (response.status !== 0) {
                        dispatch('login', data)
                    } else {
                        resolve(false)
                    }
                    resolve(true)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        signUp ({}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/signUp`, data
                ).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        forget ({}, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/auth/forget`, {
                    email: data.email
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        checkTokenExpiry({commit}) {
            let data = {
                token: localStorage.getItem('authorization'),
                expiry: localStorage.getItem('expiry'),
                id: localStorage.getItem('userId')
            }
            const expiry = data.expiry - Date.now() / 1000 > 10
            if (!expiry) {
                localStorage.clear()
                data = {
                    token: null,
                    expiry: null,
                    id: null
                }
            }
            commit('setTokenAndExpiry', data)
        },
        logout({commit}) {
            const data = {
                token: null,
                expiry: null,
                id: null
            }
            commit('setTokenAndExpiry', data)
        },
        getAccount({state, commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/usersByUser/${state.userId}`,
                ).then(response => {
                    if (!response.status) {
                        const data = {
                            token: null,
                            expiry: null,
                            id: null
                        }
                        commit('setTokenAndExpiry', data)
                        reject(data)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        putAccount({state,commit}, data){
            return new Promise((resolve, reject) => {
                axios.put(`/api/usersByUser/${state.userId}`,{
                    name: data.name,
                    group_name: data.group_name,
                    phone: data.phone,
                    subscription: data.subscription
                }).then(response => {
                    if (response.status) {
                        const data = {
                            token: null,
                            expiry: null,
                            id: null
                        }
                        commit('setTokenAndExpiry', data)
                    }
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        },
        putPassword({state}, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/usersByUser/${state.userId}/password`,{
                    old_password: data.oldPassword,
                    new_password: data.newPassword
                }).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error.response)
                })
            })
        }
    },
    mutations: {
        setTokenAndExpiry(state, data) {
            localStorage.setItem('authorization', data.token)
            localStorage.setItem('expiry', data.expiry)
            localStorage.setItem('userId', data.id)
            state.token = localStorage.getItem('authorization') === 'null' ? null : localStorage.getItem('authorization')
            state.expiry = localStorage.getItem('expiry') === 'null' ? null : localStorage.getItem('expiry')
            state.userId = localStorage.getItem('userId') === 'null' ? null : localStorage.getItem('userId')
        }
    },
    getters: {
        getToken(state) {
            return state.token
        },
        getExpiry(state) {
            return state.expiry
        },
        getUserId(state) {
            return state.userId
        }
    }
}

<template>
    <div class="ma-3">
        <v-row dense justify="end" align="center" v-if="magazines && magazinesYears">
            <v-col cols="6" md="2" >
                <v-select :items="magazinesYears" label="請選擇年份" outlined color="deep-orange" hide-details clearable v-model="findValue.year" @change="findMagazine()"></v-select>
            </v-col>
            <v-col cols="6" md="2">
                <v-select :items="months" label="請選擇月份" outlined color="deep-orange" hide-details clearable v-model="findValue.month" @change="findMagazine()"></v-select>
            </v-col>
            <v-col cols="12" md="1" class="text-center" align="center"> 或 </v-col>
            <v-col cols="12" md="2">
                <v-text-field label="請輸入期數" type="number" outlined color="deep-orange" hide-details clearable v-model="findValue.number" @change="findMagazine()"></v-text-field>
            </v-col>
            <v-col cols="12" v-for="(magazine, index) in getCurrentPageItem" :key="index">
                <v-hover v-slot="{ hover }">
                    <v-card :elevation="hover ? 5 : 2" :color="hover ? 'orange lighten-4': 'orange lighten-5'" class="ma-3">
                        <v-row align="center" class="mx-2">
                            <v-col cols="12">
                                <v-row dense align="center">
                                    <v-col cols="12" class="title"> {{ magazine.year }} 年 {{ magazine.month }} 月號 {{ magazine.number }} 期 </v-col>
                                    <v-col cols="12" class="body-1"> {{ magazine.describe }} </v-col>
                                    <v-col cols="12" class="text-right">
                                        <v-btn text color="deep-orange" target="_blank" :href="magazine.file">
                                            查看更多
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12">
                <v-pagination color="primary"
                    v-model="currentPage"
                    :length="getPages"
                    :total-visible="7"
                ></v-pagination>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog.preview">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog.preview = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ preMagazine.year }} 年 {{ preMagazine.month }} 月號 {{ preMagazine.number }} 期</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-3" v-if="preMagazine.file && preMagazine.numPages">
                    <pdf
                        :src="preMagazine.file"
                        v-for="i in preMagazine.numPages"
                        :key="i"
                        :page="i"
                    ></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import _chunk  from 'lodash/chunk'
    import pdf from 'vue-pdf'
    const createLoadingTask = pdf.createLoadingTask
    export default {
        components: {
            pdf
        },
        data () {
            return {
                months: [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
                currentPage: 1,
                pageChunk: 20,
                preMagazine: {},
                findValue: {
                    year: null,
                    month: null,
                    number: null
                },
                dialog: {
                    preview: false
                },
                pdfFile: {
                    currentPage: 0,
                    pageCount: 0,

                }
            }
        },
        computed: {
          ...mapGetters({
              magazines: 'Magazines/getMagazines',
              magazinesYears: 'Magazines/getMagazinesYears'
          }),
          getPages() {
              if (this.magazines && this.magazines.length > this.pageChunk){
                  return Math.ceil(this.magazines.length / this.pageChunk)
              }
              return 1
          },
          getCurrentPageItem() {
              return _chunk(this.magazines, this.pageChunk)[this.currentPage - 1]

          }
      },
      created() {
          this.init()
      },
      methods:{
          ...mapActions({
              getMagazines: 'Magazines/getMagazines'
          }),
          async init() {
              if (!this.magazines) {
                  await this.getMagazines()
              }
          },
          async findMagazine() {
              let request = {}
              if (this.findValue.number) {
                  request.number = this.findValue.number
                  this.findValue.year = this.findValue.month = null
              }
              if (this.findValue.year) {
                  request.year = this.findValue.year
              }
              if (this.findValue.month) {
                  request.month = this.findValue.month
              }

              if (request) {
                  await this.getMagazines(request)
              } else {
                  await this.getMagazines()
              }
          },
          preview(data) {
              this.preMagazine = { ...data }
              this.dialog.preview = true
              this.preMagazine.numPages = undefined
              if (this.preMagazine.file) {
                  const url = this.preMagazine.file
                  const loadTask = createLoadingTask({
                      url: url
                  })
                  loadTask.promise.then((pdf) => {
                      this.preMagazine.file = loadTask,
                      this.preMagazine.numPages = pdf.numPages
                  })
              }
          }
      }
    }
</script>

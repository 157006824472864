import axios from './axios.js'

export default {
    namespaced: true,
    state: {
        chamber: ''
    },
    actions: {
        getChambers ({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`/api/chamber`).then(response => {
                    commit('setChambers', response)
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        setChambers(state, data){
            state.chamber = data
        }
    },
    getters: {
        getChambers: state => {
          return state.chamber
        },
    }
}
